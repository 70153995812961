import React from "react";

const AnalyticsTable = ({ activeUsersData }) => {
  const tableColumns = ["Country", "City", "Device", "Active Users", "Browser"];
  return (
    <div className="flex flex-col  justify-center h-full py-4">
      <div className="w-full  mx-auto bg-white shadow-lg rounded-sm ">
        <div className="py-3 ">
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <thead className="text-sm w-full h-14 bg-myBg font-semibold uppercase text-gray-600">
                <tr>
                  {tableColumns.map((item, index) => (
                    <th
                      key={index}
                      className="p-2 whitespace-nowrap font-semibold text-left"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-base  divide-gray-100">
                {activeUsersData && activeUsersData.length > 0 ? (
                  <>
                    {activeUsersData.map((user, index) => (
                      <tr key={index}>
                        <td
                          className={`text-left text-xs px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-xs `}>{user.country}</p>
                        </td>
                        <td
                          className={`text-left text-xs px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-xs capitalize`}>
                            {user.city}
                          </p>
                        </td>
                        <td
                          className={`text-left text-xs px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-xs capitalize`}>
                            {user.deviceCategory}
                          </p>
                        </td>
                        <td
                          className={`text-left text-xs px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-xs `}>
                            {user.activeUsers}
                          </p>
                        </td>
                        <td
                          className={`text-left text-xs px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-xs `}>{user.browser}</p>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <div className="w-full text-center py-5">
                        No data available.
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsTable;

import React from "react";
import AnalyticsComponent from "../minor-components/AnalyticsComponent";

const Revenue = () => {
  return (
    <section className="w-[78%] ml-[20%] bg-gray-50 z-0 min-h-screen">
      <div className="pb-6 pt-28 px-6">
        <AnalyticsComponent heading="Revenue" subHeading="Sales made" />
      </div>
    </section>
  );
};

export default Revenue;

import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { useState } from "react";
import dropDown from "../../assets/down-arrow.svg";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAlert } from "react-alert";

const CouponDropdown = ({ isActive, fetchCoupons, itemId }) => {
  const alert = useAlert();
  const handleUpdateCouponPrice = async (id, status) => {
    try {
      const response = await axiosInstance.patch(
        "/api/v1/coupon/updateCouponStatus",
        {
          id,
          couponStatus: status,
        }
      );

      if (response.data.success) {
        alert.show("Coupon status updated successfully");
        fetchCoupons();
      }
    } catch (error) {
      alert.show("Coupon status failed to update");
    }
  };
  return (
    <Menu className="">
      {({ open }) => (
        <>
          <Menu.Button>
            <img
              className={`w-[15px] cursor-pointer `}
              src={dropDown}
              alt="drop down"
            />
          </Menu.Button>
          <Transition show={open}>
            <Menu.Items static className="absolute top-18 z-50 flex flex-col">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`py-2 px-4 no-underline border-1 ${
                      active ? "bg-myBg" : "bg-gray-200"
                    }`}
                    onClick={() =>
                      handleUpdateCouponPrice(
                        itemId,
                        isActive ? "deactivate" : "activate"
                      )
                    }
                  >
                    {isActive ? "Deactivate" : "Activate"}
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default CouponDropdown;

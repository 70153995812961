import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import CouponCheckBox from "./CouponCheckBox";
import { Loader } from "rsuite";
import CouponType from "./CouponType";
import CouponExpiryDate from "./CouponExpiryDate";
import CouponUse from "./CouponUse";
import CouponCategories from "./CouponCategories";

const CouponSchema = Yup.object().shape({
  couponPrice: Yup.string().required("Required"),
  id: Yup.string(),
  couponProducts: Yup.array().required("Coupon Products is required"),
  couponCategory: Yup.array().required("Coupon Products is required"),
  minOrderValue: Yup.string().required("Minimum order value required"),
  maxOrderValue: Yup.string().required("Maximum order value required"),
  totalUsageLimit: Yup.string().required("Total usage limit is required"),
  rolesApplicable: Yup.array(),
  usagePerUser: Yup.string().required("Total usage limit is required"),
  couponType: Yup.string().required("Coupon type is required"),
  expiryDate: Yup.string().required("Enter expiry date"),
  isIndividualUse: Yup.boolean(),
});
const animatedComponents = makeAnimated();
const CouponForm = ({
  initialValues,
  handleSubmit,
  isLoading,
  modalItem,
  rolesData,
  isUpdate,
}) => {
  return (
    <div className="bg-white w-full ">
      <div className="flex justify-between gap-5">
        <div className=" w-full">
          <h4 className="text-xl text-center">Add Coupon</h4>

          <div className="w-full">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={CouponSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, setFieldValue, handleChange }) => {
                return (
                  <Form className="p-4 mb-4  bg-white rounded">
                    <div className="flex justify-between gap-5 w-full">
                      <section className="w-[50%]">
                        <CouponType
                          setFieldValue={setFieldValue}
                          modalItem={modalItem}
                        />
                        <div className=" md:mr-2 md:mb-0 md:w-full">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                            htmlFor="name"
                          >
                            Coupon Amount
                          </label>
                          <Field
                            className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            type="number"
                            name="couponPrice"
                            placeholder="Enter coupon amount"
                            disabled={values.couponType === "Free Shipping"}
                          />
                          <ErrorMessage
                            className="text-red-600 text-xs"
                            name="couponPrice"
                            component="div"
                          />
                        </div>
                        <CouponCheckBox
                          setFieldValue={setFieldValue}
                          modalItem={modalItem}
                        />

                        <CouponCategories
                          setFieldValue={setFieldValue}
                          modalItem={modalItem}
                        />
                        <div className=" md:mr-2 md:mb-0 md:w-full">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                            htmlFor="minOrderValue"
                          >
                            Minimum Order Value
                          </label>
                          <Field
                            className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            type="number"
                            name="minOrderValue"
                            placeholder="Enter Price"
                          />
                          <ErrorMessage
                            className="text-red-600 text-xs"
                            name="minOrderValue"
                            component="div"
                          />
                        </div>
                      </section>
                      <section className="w-[50%]">
                        <div className=" md:mr-2 md:mb-0 md:w-full">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                            htmlFor="maxOrderValue"
                          >
                            Maximum Order Value
                          </label>
                          <Field
                            className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            type="number"
                            name="maxOrderValue"
                            placeholder="Enter Price"
                          />
                          <ErrorMessage
                            className="text-red-600 text-xs"
                            name="maxOrderValue"
                            component="div"
                          />
                        </div>
                        <div className=" md:mr-2 md:mb-0 md:w-full mt-3">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                            htmlFor="totalUsageLimit"
                          >
                            Total Usage Limit
                          </label>
                          <Field
                            className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            type="number"
                            name="totalUsageLimit"
                            placeholder="Enter usage limit"
                          />
                          <ErrorMessage
                            className="text-red-600 text-xs"
                            name="totalUsageLimit"
                            component="div"
                          />
                        </div>
                        <div className="mt-4 md:mr-2 md:mb-0 md:w-full">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                            htmlFor="usagePerUser"
                          >
                            Usage Limit Per User
                          </label>
                          <Field
                            className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            type="number"
                            name="usagePerUser"
                            placeholder="Enter usage limit"
                          />
                          <ErrorMessage
                            className="text-red-600 text-xs"
                            name="usagePerUser"
                            component="div"
                          />
                        </div>

                        <div className=" md:mr-2 md:mb-0 md:w-full my-5">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                            htmlFor="rolesApplicable"
                          >
                            Roles Applicable
                          </label>

                          <Select
                            closeMenuOnSelect={false}
                            className="w-full my-2"
                            components={animatedComponents}
                            name="rolesApplicable"
                            defaultValue={
                              modalItem && modalItem.rolesApplicable
                                ? modalItem.rolesApplicable
                                : []
                            }
                            isMulti
                            options={rolesData}
                            onChange={(value) =>
                              setFieldValue("rolesApplicable", value)
                            }
                          />
                          <ErrorMessage
                            className="text-red-600 text-xs"
                            name="rolesApplicable"
                            component="div"
                          />
                        </div>
                        <CouponExpiryDate
                          setFieldValue={setFieldValue}
                          modalItem={modalItem}
                        />

                        <CouponUse
                          setFieldValue={setFieldValue}
                          modalItem={modalItem}
                        />
                      </section>
                    </div>

                    <div className="mt-6 flex items-center justify-center gap-2 sm:flex-col text-center">
                      {isUpdate ? (
                        <button
                          className="flex items-center justify-center cursor-pointer gap-2 w-full max-w-[200px] py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isLoading ? <Loader /> : "Update Coupon"}
                        </button>
                      ) : (
                        <button
                          className="flex items-center justify-center cursor-pointer gap-2 w-full max-w-[200px] py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isLoading ? <Loader /> : "Generate Coupon"}
                        </button>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponForm;

import React, { useEffect, useState } from "react";
import AnalyticsCard from "./AnalyticsCard";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import {
  getTotalRevenue,
  getTotalRevenueByMonth,
  allMonths,
  getTotalRevenueByRange,
} from "../../utils/revenue";
import { useAlert } from "react-alert";
import StockRevenue from "./StockRevenue";

const AnalyticsComponent = ({ heading, subHeading }) => {
  const alert = useAlert();

  const [loadingRevenue, setLoadingRevenue] = useState(false);
  const [revenueData, setRevenueData] = useState(0);
  const [month, setMonth] = useState("");
  const [loadingMonthlyRev, setLoadingMonthlyRev] = useState(false);
  const [monthlyRevenue, setMonthlyRevenue] = useState(0);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [loadingRange, setLoadingRange] = useState(false);
  const [rangeData, setRangeData] = useState(0);

  const getRevenue = async () => {
    const revenue = await getTotalRevenue(setLoadingRevenue, alert);

    if (!loadingRevenue) {
      setRevenueData(revenue);
    }
  };

  const getMonthlyRevenue = async (e) => {
    setMonth(e.target.value);
    const payload = {
      month: e.target.value,
    };
    const revenue = await getTotalRevenueByMonth(setLoadingMonthlyRev, payload);

    if (!loadingMonthlyRev) {
      setMonthlyRevenue(revenue);
    }
  };

  const getMonthlyTotalRevenue = async () => {
    const date = new Date();
    const payload = {
      month: date.getMonth() + 1,
    };
    const revenue = await getTotalRevenueByMonth(setLoadingMonthlyRev, payload);
    if (!loadingMonthlyRev) {
      setMonthlyRevenue(revenue);
    }
  };

  const getWeeklyTotalRevenue = async () => {
    const payload = {
      dateRange,
    };
    const revenue = await getTotalRevenueByRange(setLoadingRange, payload);

    if (!loadingRange) {
      setRangeData(revenue);
    }
  };

  const handleChangeDatePicker = async (update) => {
    setDateRange(update);
    console.log({ update, startDate, endDate });
    const payload = {
      dateRange: update,
    };
    const revenue = await getTotalRevenueByRange(setLoadingRange, payload);

    if (!loadingRange) {
      setRangeData(revenue);
    }
  };

  useEffect(() => {
    getRevenue();
  }, []);

  useEffect(() => {
    getMonthlyTotalRevenue();
  }, []);

  useEffect(() => {
    getWeeklyTotalRevenue();
  }, []);
  return (
    <>
      <article className="w-full py-5">
        <h2 className="text-3xl mx-0 px-0">{heading}</h2>
        <p className="text-xs ml-1">{subHeading}</p>
      </article>
      <section className=" w-full my-4 py-1">
        <div className="grid gap-4 grid-cols-3 2xl:grid-cols-4 w-full">
          <AnalyticsCard
            icon={<RiMoneyEuroCircleFill />}
            title="Total Income"
            data={revenueData}
            isIncome={true}
            isLoading={loadingRevenue}
          />
          <AnalyticsCard
            icon={<RiMoneyEuroCircleFill />}
            title="Total Monthly Income"
            data={monthlyRevenue}
            showFilter={true}
            optionsData={allMonths.map((month, i) => (
              <option value={month.number} className="capitalize" key={i}>
                {month.name}
              </option>
            ))}
            handleChange={getMonthlyRevenue}
            value={month}
            isIncome={true}
            isLoading={loadingMonthlyRev}
          />
          <AnalyticsCard
            icon={<RiMoneyEuroCircleFill />}
            title="Total Weekly/Daily Income"
            data={rangeData}
            showFilter={false}
            showDatePicker={true}
            startDate={startDate}
            endDate={endDate}
            handleChange={handleChangeDatePicker}
            value={month}
            isIncome={true}
            isLoading={loadingRange}
          />
        </div>

        <div className="grid gap-4 mt-5 grid-cols-3 2xl:grid-cols-4 w-full">
          <StockRevenue />
        </div>
      </section>
    </>
  );
};

export default AnalyticsComponent;

import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Loader } from "../minor-components/Loader";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAlert } from "react-alert";

const RoleSchema = Yup.object().shape({
  role: Yup.string().required("Required"),
});

export default function Pricing() {
  const alert = useAlert();
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [rolesData, setRolesData] = useState([]);

  const initialValues = {
    role: "",
  };

  const handleRoleNameChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        role: values.role,
      };
      const response = await axiosInstance.post("/api/v1/role/createRole", {
        ...payload,
      });

      if (response.data.success) {
        alert.show("Role created successfully");
        fetchAllRoles();
      }
      setIsLoading(false);
      values.role = "";
    } catch (error) {
      alert.show(error.message);
    }
  };

  const fetchAllRoles = async () => {
    try {
      setLoadingRoles(true);
      const response = await axiosInstance.get("/api/v1/role/getAllRoles");

      if (response.data.success) {
        setLoadingRoles(false);
        setRolesData(response.data.data);
      }
    } catch (error) {}
  };

  const handleDeleteRole = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/v1/role/${id}`);
      if (response.data.success) {
        alert.show("Role deleted successfully!");
        fetchAllRoles();
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);

  return (
    <div className="divide-y  divide-gray-100 bg-white rounded-lg mt-4 shadow-lg">
      <div className="px-5 py-4 my-0 flex flex-col items-start justify-between">
        <h2 className="font-semibold text-gray-800 text-lg">Pricing Roles</h2>
        <p className="text-xs ml-1">
          Note that the prices indicated below will be applied to the whole
          range of products you specified in the products/categories sections.
          Be careful to not mess up the pricing.
        </p>
      </div>
      <div className="flex gap-5 py-8 justify-between">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={RoleSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue, handleChange }) => {
            return (
              <Form className="px-8 pt-6 pb-8 mb-4  ">
                <div className="flex flex-col justify-around my-3">
                  <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-40 md:w-full md:mb-0 flex flex-col gap-4">
                    <div className=" md:mr-2 md:mb-0 md:w-full">
                      <label
                        className="block mb-2 text-sm mt-2 font-bold text-gray-700 md:mt-2"
                        htmlFor="categories"
                      >
                        Enter New Role
                      </label>
                      <Field
                        className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                        type="text"
                        name="role"
                        placeholder="Enter role"
                        onChange={(e) => {
                          handleChange(e);
                          handleRoleNameChange(e);
                        }}
                      />
                      <ErrorMessage
                        className="text-red-600 text-xs font-thin"
                        name="role"
                        component="div"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex gap-2 sm:flex-col text-center">
                  <button
                    className="flex items-center justify-center cursor-pointer gap-2 w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Create Role
                    {isLoading && <Loader />}
                  </button>
                  <button
                    className={`w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline ${
                      !status ? "hidden" : ""
                    }`}
                    type="reset"
                  >
                    Reset
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
        <ul className="regions border border-gray-100 py-2 mr-8 max-w-[400px] w-full max-h-[200px] overflow-y-auto px-2 rounded-md flex flex-wrap gap-2 items-start">
          {!loadingRoles && (
            <>
              {rolesData &&
                rolesData.length > 0 &&
                rolesData.map((roles, i) => (
                  <li
                    className="flex gap-2 items-center justify-between px-3 py-1 bg-gray-200 rounded-3xl"
                    key={i}
                  >
                    <span className="text-xs capitalize">{roles.role}</span>
                    <button
                      className="text-xs"
                      onClick={() => handleDeleteRole(roles._id)}
                    >
                      <FontAwesomeIcon icon="times" />
                    </button>
                  </li>
                ))}
            </>
          )}
        </ul>
      </div>
    </div>
  );
}

{
  /* <div className="flex flex-col justify-around my-3">
  <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-40 md:w-full md:mb-0 flex flex-col gap-4">
    <div className=" md:mr-2 md:mb-0 md:w-full">
      <label
        className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
        htmlFor="name"
      >
        Pricing type
      </label>
      <div className="flex flex-col w-full">
        <div className="flex gap-2">
          <Field type="radio" name="pricingModel" value="flat" />
          <label htmlFor="flat">Flat prices</label>
        </div>
        <div className="flex gap-2">
          <Field type="radio" name="pricingModel" value="percentage" />
          <label htmlFor="percentage">Percentage discount</label>
        </div>
      </div>
    </div>
    <div className=" md:mr-2 md:mb-0 md:w-full">
      <label
        className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
        htmlFor="name"
      >
        Regular Price (€)
      </label>
      <Field
        className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        type="text"
        name="title"
        placeholder="Specify the regular price"
      />
      <ErrorMessage
        className="text-red-600 text-xs"
        name="title"
        component="div"
      />
    </div>
    <div className=" md:mr-2 md:mb-0 md:w-full">
      <label
        className="block mb-2 text-sm mt-2 font-bold text-gray-700 md:mt-2"
        htmlFor="categories"
      >
        Sale price (€)
      </label>
      <Field
        className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        type="text"
        name="categories"
        placeholder="Specify the sale price"
      />
      <ErrorMessage
        className="text-red-600 text-xs font-thin"
        name="brand"
        component="div"
      />
    </div>
  </div>
</div>; */
}

import React, { useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getCategories } from "../../redux/Actions/CategoryActions";

const CouponCategories = ({ modalItem, setFieldValue }) => {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categoryReducer);
  const [isAllCategories, setIsAllCategories] = useState(
    false ||
      (modalItem &&
        modalItem.couponCategory &&
        modalItem.couponCategory.includes("All Categories"))
  );

  const [allProducts, setAllProducts] = useState([]);

  const handleCheckboxChange = (e, setFieldValue) => {
    const isChecked = e.target.checked;
    setIsAllCategories(isChecked);
    if (isChecked) {
      setFieldValue("couponCategory", ["All Categories"]);
    } else {
      setFieldValue("couponCategory", "");
    }
  };

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      const newProducts = categories.map((product) => {
        return {
          value: product.category[0],
          label: product.category[0],
        };
      });
      setAllProducts(newProducts);
    }
  }, [categories]);
  return (
    <div className=" md:mr-2 md:mb-0 md:w-full my-5">
      <label
        className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
        htmlFor="couponCategory"
      >
        Coupon Category
      </label>

      <Select
        closeMenuOnSelect={false}
        className="w-full my-2 shadow"
        components={animatedComponents}
        name="couponCategory"
        defaultValue={
          modalItem && modalItem.couponCategory && modalItem.couponCategory
        }
        isMulti
        options={allProducts}
        onChange={(value) => setFieldValue("couponCategory", value)}
        isDisabled={isAllCategories}
      />
      <ErrorMessage
        className="text-red-600 text-xs"
        name="couponCategory"
        component="div"
      />

      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          id="allProducts"
          className="mr-2"
          checked={isAllCategories}
          onChange={(e) => handleCheckboxChange(e, setFieldValue)}
        />
        <label
          htmlFor="allProducts"
          className="text-sm font-normal text-gray-700"
        >
          All Categories
        </label>
      </div>
    </div>
  );
};

export default CouponCategories;

import React from "react";
import ReactDom from "react-dom";
import { IoMdClose } from "react-icons/io";

const CouponModal = ({ open, onClose, children }) => {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <section className="w-full fixed top-0 bottom-0 bg-[rgba(0,0,0,0.5)] backdrop-blur-md left-0 right-0 z-50 ">
        <div className="flex w-full h-full justify-center items-center overflow-y-auto">
          <div className="bg-white max-w-[600px] w-full mx-auto px-5 py-3">
            <div className="flex w-full justify-end">
              <button className="text-lg" onClick={onClose}>
                <IoMdClose className="h-5 w-5 text-black" />
              </button>
            </div>
            {children}
          </div>
        </div>
      </section>
    </>,
    document.getElementById("portal")
  );
};

export default CouponModal;

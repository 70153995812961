import { axiosInstance } from "../constants/axiosInstance";

export const getTotalRevenue = async (setLoading, alert) => {
  try {
    setLoading(true);
    const response = await axiosInstance.get("/api/v1/revenue/getTotalRevenue");

    if (response.data.success) {
      alert.show(response.data.message);
    }

    setLoading(false);

    return response.data.data;
  } catch (error) {
    console.log(error);
    //  alert.show("Role created successfully");
  }
};

export const getTotalRevenueByMonth = async (setLoading, payload) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/api/v1/revenue/getTotalRevenueByMonth",
      payload
    );

    if (response.data.success) {
      // alert.show(response.data.message);
    }

    setLoading(false);

    return response.data.data;
  } catch (error) {
    console.log(error);
    //  alert.show("Role created successfully");
  }
};

export const getTotalRevenueByRange = async (setLoading, payload) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/api/v1/revenue/getTotalRevenueByRange",
      payload
    );

    if (response.data.success) {
      // alert.show(response.data.message);
    }

    setLoading(false);

    return response.data.data;
  } catch (error) {
    console.log(error);
    //  alert.show("Role created successfully");
  }
};

export const allMonths = [
  { name: "January", number: 1 },
  { name: "February", number: 2 },
  { name: "March", number: 3 },
  { name: "April", number: 4 },
  { name: "May", number: 5 },
  { name: "June", number: 6 },
  { name: "July", number: 7 },
  { name: "August", number: 8 },
  { name: "September", number: 9 },
  { name: "October", number: 10 },
  { name: "November", number: 11 },
  { name: "December", number: 12 },
];

export const getTotalStockByCategory = async (setLoading, payload) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/api/v1/revenue/getTotalStockByCategory",
      payload
    );

    if (response.data.success) {
      // alert.show(response.data.message);
    }

    setLoading(false);

    return response.data.data;
  } catch (error) {
    console.log(error);
    //  alert.show("Role created successfully");
  }
};

export const getTotalProductsSoldByDate = async (setLoading, payload) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/api/v1/revenue/getTotalProductsSoldByDate",
      payload
    );

    if (response.data.success) {
      // alert.show(response.data.message);
    }

    setLoading(false);

    return response.data.data;
  } catch (error) {
    console.log(error);
    //  alert.show("Role created successfully");
  }
};

export const getTotalShippingChargesByDateRange = async (
  setLoading,
  payload
) => {
  try {
    setLoading(true);
    const response = await axiosInstance.post(
      "/api/v1/revenue/getTotalShippingChargesByDateRange",
      payload
    );

    if (response.data.success) {
      // alert.show(response.data.message);
    }

    setLoading(false);

    return response.data.data;
  } catch (error) {
    console.log(error);
    //  alert.show("Role created successfully");
  }
};

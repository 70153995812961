import React, { useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../redux/Actions/ProductActions";

const CouponCheckBox = ({ setFieldValue, modalItem }) => {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.productReducer);
  const [isAllProducts, setIsAllProducts] = useState(
    false ||
      (modalItem &&
        modalItem.couponProducts &&
        modalItem.couponProducts.includes("All Products"))
  );

  const [allProducts, setAllProducts] = useState([]);

  const handleCheckboxChange = (e, setFieldValue) => {
    const isChecked = e.target.checked;
    setIsAllProducts(isChecked);
    if (isChecked) {
      setFieldValue("couponProducts", ["All Products"]);
    } else {
      setFieldValue("couponProducts", "");
    }
  };

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      const newProducts = products.map((product) => {
        return {
          value: product.name,
          label: product.name,
        };
      });
      setAllProducts(newProducts);
    }
  }, [products]);

  return (
    <div className=" md:mr-2 md:mb-0 md:w-full my-5">
      <label
        className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
        htmlFor="couponProducts"
      >
        Coupon Products
      </label>

      <Select
        closeMenuOnSelect={false}
        className="w-full my-2 shadow"
        components={animatedComponents}
        name="couponProducts"
        defaultValue={
          modalItem && modalItem.couponProducts && modalItem.couponProducts
        }
        isMulti
        options={allProducts}
        onChange={(value) => setFieldValue("couponProducts", value)}
        isDisabled={isAllProducts}
      />
      <ErrorMessage
        className="text-red-600 text-xs"
        name="couponProducts"
        component="div"
      />

      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          id="allProducts"
          className="mr-2"
          checked={isAllProducts}
          onChange={(e) => handleCheckboxChange(e, setFieldValue)}
        />
        <label
          htmlFor="allProducts"
          className="text-sm font-normal text-gray-700"
        >
          All Products
        </label>
      </div>
    </div>
  );
};

export default CouponCheckBox;

import React, { useState } from "react";
import AnalyticsCard from "./AnalyticsCard";
import { FaUsers } from "react-icons/fa";
import AnalyticsTable from "./AnalyticsTable";

const TrafficComponent = ({
  googleLogin,
  activeUsers,
  activeUsersData,
  handleChangeDatePicker,
  startDate,
  endDate,
  isLoading,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  console.log({ activeUsersData });

  const handleChange = (e) => {
    setInputValue(e.target.value);
    const lowercaseValue = e.target.value.toLowerCase();
    if (activeUsersData.length > 0) {
      const filtered_data = activeUsersData.filter((data) => {
        return (
          data.deviceCategory.toLowerCase().includes(lowercaseValue) ||
          data.country.toLowerCase().includes(lowercaseValue) ||
          data.city.toLowerCase().includes(lowercaseValue) ||
          data.browser.toLowerCase().includes(lowercaseValue)
        );
      });

      setFilteredData(filtered_data);
    }
  };
  return (
    <>
      <section className="flex justify-between items-center gap-5">
        <article className="w-full py-5">
          <h2 className="text-3xl mx-0 px-0">Traffic</h2>
          <p className="text-xs ml-1">Website analytics</p>
        </article>

        <div className="w-fit">
          <button
            onClick={googleLogin}
            className="capitalize w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
          >
            show analytics
          </button>
        </div>
      </section>
      <section className=" w-full my-4 py-1">
        <div className="grid gap-4 grid-cols-3 2xl:grid-cols-4 w-full">
          <AnalyticsCard
            icon={<FaUsers />}
            title="Total Active Users"
            data={activeUsers}
            showFilter={false}
            showDatePicker={true}
            startDate={startDate}
            endDate={endDate}
            handleChange={handleChangeDatePicker}
            // value={month}
            isLoading={isLoading}
          />
        </div>
      </section>

      <section className="mb-5 mt-10 w-full">
        <div className=" w-full flex justify-end">
          <div className="w-full max-w-[300px] h-[40px] border border-gray-200 rounded-md overflow-hidden">
            <input
              type="text"
              className="w-full h-full border-none outline-none px-2 "
              placeholder="Search by country, city, device or browser"
              value={inputValue}
              onChange={handleChange}
            />
          </div>
        </div>
        <AnalyticsTable
          activeUsersData={
            filteredData.length > 0 ? filteredData : activeUsersData
          }
        />
      </section>
    </>
  );
};

export default TrafficComponent;

import React, { useState } from "react";
import { FaPencil } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAlert } from "react-alert";
import {
  getUnApprovedUsers,
  getDeactivateUsers,
} from "../../redux/Actions/UserActions";
import { useDispatch } from "react-redux";
import { Loader } from "rsuite";

const EditPointsComponent = ({ point, userId }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [enable, setEnable] = useState(true);
  const [searchInput, setSearchInput] = useState(point);
  const [loading, setLoading] = useState(false);

  const handleUpdateUserPoint = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.patch(
        "/api/v1/user/updateUserPoints",
        { id: userId, point: Number(searchInput) }
      );

      if (response.data.success) {
        alert.show("User points updated successfully");
        dispatch(getUnApprovedUsers());
        dispatch(getDeactivateUsers());
        setLoading(false);
        setEnable(true);
      }
      setLoading(false);
    } catch (error) {
      alert.show(error.message);
    }
  };
  return (
    <div className="flex w-fit gap-2">
      <input
        type="number"
        value={searchInput}
        disabled={enable}
        onChange={(e) => setSearchInput(e.target.value)}
        className="max-w-[60px] border border-gray-300 tex-sm px-1 outline-none"
      />
      {enable ? (
        <button
          className="text-gray-800 bg-[#E9C95D] p-2 rounded-md"
          onClick={() => setEnable(false)}
        >
          <FaPencil />
        </button>
      ) : (
        <button
          className="text-gray-800 bg-[#E9C95D] p-2 rounded-md"
          onClick={handleUpdateUserPoint}
        >
          {loading ? <Loader /> : <FaCheck />}
        </button>
      )}
    </div>
  );
};

export default EditPointsComponent;

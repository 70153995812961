import React, { useState } from "react";
import DatePicker from "react-datepicker";
const CouponExpiryDate = ({ setFieldValue, modalItem }) => {
  const [startDate, setStartDate] = useState(
    modalItem && modalItem.expiryDate ? modalItem.expiryDate : new Date()
  );

  const handleChange = (date) => {
    setStartDate(date);
    setFieldValue("expiryDate", date);
  };
  return (
    <div className=" md:mr-2 md:mb-0 md:w-full">
      <label
        className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
        htmlFor="expiryDate"
      >
        Expiry Date
      </label>

      <div
        className={`border border-gray-400 h-7 rounded-[4px] overflow-hidden shadow`}
      >
        <DatePicker
          className="w-full h-full px-2 bg-transparent focus:outline-none"
          selected={startDate}
          onChange={handleChange}
          isClearable={true}
          placeholderText="Enter expiry date"
        />
      </div>
    </div>
  );
};

export default CouponExpiryDate;

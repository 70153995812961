import logo2 from "../../assets/logo.png";
import { useState } from "react";
import dashboardHome from "../../assets/dashboard-home.svg";
import product from "../../assets/product.svg";
import order from "../../assets/order.svg";
import analytics from "../../assets/analytics.svg";
import driver from "../../assets/driver.svg";
import category from "../../assets/category.svg";
import logout from "../../assets/logout.svg";
import { IconBg } from "../minor-components/IconBg";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import coupon from "../../assets/coupon.svg";

const SideBar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const sidebarData = [
    {
      title: "Dashboard",
      path: "/",
      svg: dashboardHome,
    },
    {
      title: "Categories",
      path: "/categories",
      svg: category,
    },
    {
      title: "Products",
      path: "/products",
      svg: product,
    },
    {
      title: "Orders",
      path: "/orders",
      svg: order,
    },

    {
      title: "Coupons",
      path: "/coupons",
      svg: coupon,
    },

    {
      title: "Users",
      path: "/users",
      svg: driver,
    },
    {
      title: "Drivers",
      path: "/drivers",
      svg: driver,
    },
    {
      title: "Earning",
      path: "/earnings",
      svg: order,
    },
    {
      title: "Analytics",
      path: "/analytics",
      svg: analytics,
    },
    {
      title: "Website Pages",
      path: "/pages",
      svg: category,
    },
    {
      title: "Settings",
      path: "/settings",
      svg: category,
    },
    {
      title: "Logout",
      path: "/logout",
      svg: logout,
    },
  ];

  return (
    <>
      <div
        style={{ scrollbarWidth: "none" }}
        className=" shadow-xl bg-white fixed overflow-y-auto scroll-thin top-0 h-full left-0 w-[18%] 
             md:hidden"
      >
        <div className=" h-24 bg-gray-50 flex justify-center items-center p-0 m-0">
          <img className="mx-auto mt-0 w-[85px]" src={logo2} alt="logo" />
        </div>

        <ul className="flex flex-col">
          {sidebarData.map((item, index) => (
            <li
              key={index}
              className={`font-semibold p-5 flex justify-between  cursor-pointer`}
            >
              <IconBg svg={item.svg} />
              <div className="flex-1 flex justify-between items-center  pl-[20%] text-xs">
                {item.path === "/logout" ? (
                  <p
                    className="text-gray-800"
                    onClick={() => {
                      localStorage.removeItem("token");
                      navigate("/login");
                    }}
                  >
                    {item.title}
                  </p>
                ) : item.childrens ? (
                  <div
                    className={
                      open ? "sidebar-item w-full open" : "sidebar-item w-full"
                    }
                  >
                    <div className="sidebar-title w-full flex justify-between ">
                      <span>
                        {item.icon && <i className={item.icon}></i>}
                        {item.title}
                      </span>
                      <FontAwesomeIcon
                        className=" bi-chevron-down toggle-btn "
                        icon="fa-solid fa-angle-up fa-2xl"
                        onClick={() => setOpen(!open)}
                        size="lg"
                      />
                    </div>
                  </div>
                ) : (
                  <NavLink
                    end
                    to={item.path}
                    className={({ isActive }) =>
                      isActive ? "text-myBg" : "text-gray-800"
                    }
                  >
                    {item.title}
                  </NavLink>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default SideBar;

import React, { useEffect } from "react";
import {
  getUnApprovedUsers,
  getDeactivateUsers,
} from "../../redux/Actions/UserActions";
import { useDispatch } from "react-redux";
import { Menu, Transition } from "@headlessui/react";
import { useState } from "react";
import dropDown from "../../assets/down-arrow.svg";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAlert } from "react-alert";

const UsersDropdown = ({ userId }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const fetchAllRoles = async () => {
    try {
      setLoadingRoles(true);
      const response = await axiosInstance.get("/api/v1/role/getAllRoles");

      if (response.data.success) {
        setLoadingRoles(false);
        setRolesData(response.data.data);
      }
    } catch (error) {}
  };

  const handleUpdateUserId = async (id, role) => {
    try {
      const response = await axiosInstance.patch(
        "/api/v1/user/updateUserRole",
        { id, role }
      );

      if (response.data.success) {
        alert.show("User role updated successfully");
        dispatch(getUnApprovedUsers());
        dispatch(getDeactivateUsers());
      }
    } catch (error) {
      alert.show(error.message);
    }
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);
  return (
    <>
      <Menu className="">
        {({ open }) => (
          <>
            <Menu.Button>
              <img
                className={`w-[15px] cursor-pointer `}
                src={dropDown}
                alt="drop down"
              />
            </Menu.Button>
            <Transition show={open}>
              <Menu.Items static className="absolute top-18 z-50 flex flex-col">
                {loadingRoles ? (
                  <Menu.Item>
                    {({ active }) => (
                      <p
                        className={`capitalize py-2 px-4 no-underline border-1 ${
                          active ? "bg-myBg" : "bg-gray-200"
                        }`}
                      >
                        Loading...
                      </p>
                    )}
                  </Menu.Item>
                ) : (
                  <>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => handleUpdateUserId(userId, "customer")}
                          className={`capitalize py-2 px-4 no-underline border-1 ${
                            active ? "bg-myBg" : "bg-gray-200"
                          }`}
                        >
                          default
                        </button>
                      )}
                    </Menu.Item>
                    {rolesData.map((roles, i) => {
                      return (
                        <Menu.Item key={i}>
                          {({ active }) => (
                            <button
                              onClick={() =>
                                handleUpdateUserId(userId, roles.role)
                              }
                              className={`capitalize py-2 px-4 no-underline border-1 ${
                                active ? "bg-myBg" : "bg-gray-200"
                              }`}
                            >
                              {roles.role}
                            </button>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </>
                )}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default UsersDropdown;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import TrafficComponent from "../minor-components/TrafficComponent";

const Analytics = () => {
  const [activeUsers, setActiveUsers] = useState(0);
  const [activeUsersData, setActiveUserData] = useState([]);
  const [dateRange, setDateRange] = useState([
    new Date("2024-10-01"),
    new Date(),
  ]);
  const [startDate, endDate] = dateRange;
  const [access_token, setAccessToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    responseType: "token",
    scope: "https://www.googleapis.com/auth/analytics",
    onSuccess: async (tokenResponse) => {
      console.log({ tokenResponse });
      const accessToken = tokenResponse?.access_token;

      if (accessToken) {
        fetchData(accessToken);
      }
      setAccessToken(accessToken);
    },
    onError: (error) => {
      console.error("ERROR", error);
    },
  });

  const fetchData = async (accessToken) => {
    try {
      const newStartDate = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;

      const newEndDate = `${endDate.getFullYear()}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`;

      setIsLoading(true);

      const metrics = [{ name: "activeUsers" }];
      const dimensions = [
        { name: "country" },
        { name: "deviceCategory" },
        { name: "browser" },
        { name: "city" },
      ];

      const requestBody = {
        dateRanges: [{ startDate: newStartDate, endDate: newEndDate }],
        metrics,
        dimensions,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };

      const apiResponse = await axios.post(
        `https://analyticsdata.googleapis.com/v1beta/properties/${process.env.REACT_APP_PROPERTY_ID}:runReport`,
        requestBody,
        { headers }
      );

      const responseData = apiResponse.data;
      console.log("responseData", responseData);

      if (responseData?.rows && responseData?.rows.length > 0) {
        const activeUsersData = responseData?.rows?.map((row) => ({
          country: row.dimensionValues[0].value,
          deviceCategory: row.dimensionValues[1].value,
          activeUsers: Number(row.metricValues[0].value),
          browser: row.dimensionValues[2].value,
          city: row.dimensionValues[3].value,
        }));
        setActiveUserData(activeUsersData);

        // Calculate the total active users
        const total = activeUsersData.reduce(
          (sum, user) => sum + user.activeUsers,
          0
        );
        setActiveUsers(total);
        setIsLoading(false);
      } else {
        setActiveUserData([]);
        setActiveUsers(0);
      }
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  useEffect(() => {
    if (googleLogin.tokenResponse?.access_token) {
      fetchData(googleLogin.tokenResponse.access_token);
    }
  }, [googleLogin.tokenResponse]);

  const handleChangeDatePicker = async (update) => {
    setDateRange(update);
  };

  useEffect(() => {
    if (startDate && endDate) {
      console.log({ startDate, endDate });
      if (access_token) {
        fetchData(access_token);
      } else {
        googleLogin();
      }
    }
  }, [access_token, endDate, googleLogin, startDate]);

  return (
    <section className="w-[78%] ml-[20%] bg-gray-50 z-0 min-h-screen">
      <div className="pb-6 pt-28 px-6">
        <TrafficComponent
          googleLogin={googleLogin}
          activeUsers={activeUsers}
          activeUsersData={activeUsersData}
          handleChangeDatePicker={handleChangeDatePicker}
          dateRange={dateRange}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </section>
  );
};

export default Analytics;

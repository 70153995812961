export const ACTION_TYPES = {
    //products
    SET_PRODUCT: "SET_PRODUCT",
    GET_PRODUCTS: "GET_PRODUCTS",
    DELETE_PRODUCTS: "DELETE_PRODUCTS",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",

    //Categories
    SET_CATEGORY: "SET_CATEGORY",
    GET_CATEGORIES: "GET_CATEGORIES",
    DELETE_CATEGORIES: "DELETE_CATEGORIES",
    UPDATE_CATEGORY: "UPDATE_CATEGORY",

    //drivers
    GET_UNAPPROVED_DRIVERS: "GET_UNAPPROVED_DRIVERS",
    UPDATE_DRIVER_STATUS: "UPDATE_DRIVER_STATUS",
    DELETE_DRIVER: "DELETE_DRIVER",

    //Users
    GET_UNAPPROVED_USERS: "GET_UNAPPROVED_USERS",
    GET_DEACTIVATE_USERS: "GET_DEACTIVATE_USERS",
    UPDATE_USER_STATUS: "UPDATE_USER_STATUS",
    DELETE_USER: "DELETE_USER",

    //Blogs
    SET_BLOG: "SET_BLOG",
    GET_BLOGS: "GET_BLOGS",
    GET_SINGLE_BLOG: "GET_SINGLE_BLOG",
    DELETE_BLOG: "DELETE_BLOG",
    UPDATE_BLOG: "UPDATE_BLOG",

    //progress
    PROGRESS_BAR_STATE: "PROGRESS_BAR_STATE",

    //login
    LOGIN: "LOGIN"
}
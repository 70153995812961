import React, { useState, useEffect } from "react";

import CouponModal from "./CouponModal";

import { axiosInstance } from "../../constants/axiosInstance";
import CouponDropdown from "./CouponDropdown";
import CouponForm from "./CouponForm";

const CouponsTable = ({
  checkBox = true,
  isOpen,
  modal,
  searchText = "",
  data,
  pagesVisited,
  itemsPerPage,
  setIsUpdate,
  isUpdate,
  handleSelectedID,
  myFilteredData,
  fetchCoupons,
  alert,
  openModal,
  modalItem,
  setModalItem,
}) => {
  const tableColumns = [
    "Coupon Code",
    "Coupon Price",
    "Coupon Status",
    "Number of Users",
    "Activated",
    "Details",
    "Actions",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);

  const initialValues = {
    couponPrice: modalItem ? modalItem.couponPrice : "",
    id: modalItem ? modalItem._id : "",
    couponProducts:
      modalItem && modalItem !== undefined ? modalItem.couponProducts : "",
    couponCategory:
      modalItem && modalItem !== undefined ? modalItem.couponCategory : "",
    minOrderValue: modalItem ? modalItem.minOrderValue : "",
    maxOrderValue: modalItem ? modalItem.maxOrderValue : "",
    totalUsageLimit: modalItem ? modalItem.totalUsageLimit : "",
    rolesApplicable: modalItem ? modalItem.rolesApplicable : "",
    usagePerUser: modalItem ? modalItem.usagePerUser : "",
    couponType: modalItem ? modalItem.couponType : "",
    expiryDate: modalItem ? modalItem.expiryDate : "",
    isIndividualUse: modalItem ? modalItem.isIndividualUse : "",
  };

  const handleSubmit = async (values) =>
    !isUpdate ? handleCreateCoupon(values) : handleUpdateCouponPrice(values);

  // const handleSubmit = async (values) => console.log(values);

  const handleCreateCoupon = async (values) => {
    try {
      setIsLoading(true);

      const couponProducts = values.couponProducts;
      const rolesApplicable = values.rolesApplicable;

      const response = await axiosInstance.post("/api/v1/coupon/createCoupon", {
        couponPrice: values.couponPrice,
        couponProducts,
        couponCategory: values.couponCategory,
        minOrderValue: values.minOrderValue,
        totalUsageLimit: values.totalUsageLimit,
        rolesApplicable,
        usagePerUser: values.usagePerUser,
        couponType: values.couponType,
        expiryDate: values.expiryDate,
        maxOrderValue: values.maxOrderValue,
        isIndividualUse: values.isIndividualUse,
      });

      if (response.data.success) {
        alert.show("Coupon generated successfully");
        setIsLoading(false);
        fetchCoupons();
        modal();
      }
    } catch (error) {
      alert.show("Failed to create coupon");
    }
  };

  const handleShowUpdate = (id) => {
    setIsUpdate(true);
    const itemExists =
      data && data.length > 0 ? data.find((item) => item._id === id) : {};
    if (itemExists) {
      setModalItem(itemExists);
    }

    openModal();
  };

  const handleUpdateCouponPrice = async (values) => {
    console.log({ values });
    try {
      setIsLoading(true);
      const couponProducts = values.couponProducts;
      const rolesApplicable = values.rolesApplicable;

      const response = await axiosInstance.patch(
        "/api/v1/coupon/updateCouponPrice",
        {
          id: values.id,
          couponPrice: values.couponPrice,
          couponProducts,
          couponCategory: values.couponCategory,
          minOrderValue: values.minOrderValue,
          totalUsageLimit: values.totalUsageLimit,
          rolesApplicable,
          usagePerUser: values.usagePerUser,
          couponType: values.couponType,
          expiryDate: values.expiryDate,
          maxOrderValue: values.maxOrderValue,
          isIndividualUse: values.isIndividualUse,
        }
      );

      if (response.data.success) {
        alert.show("Coupon updated successfully");
        fetchCoupons();
        setIsLoading(false);
        modal();
      }
    } catch (error) {
      alert.show("Coupon price failed to update");
      setIsLoading(false);
    }
  };
  const fetchAllRoles = async () => {
    try {
      setLoadingRoles(true);
      const response = await axiosInstance.get("/api/v1/role/getAllRoles");

      if (response.data.success) {
        setLoadingRoles(false);
        console.log({ roles: response.data.data });
        const newRoles =
          response.data.data.length > 0 &&
          response.data.data.map((roles) => ({
            value: roles.role,
            label: roles.role,
          }));
        // setRolesData(response.data.data);
        setRolesData(newRoles);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);
  return (
    <div className="flex flex-col  justify-center h-full">
      <div className="w-full  mx-auto bg-white  ">
        <div className="py-3 ">
          <div className="overflow-x-auto">
            <table className="table-auto w-full ">
              <thead className="text-sm w-full h-14 bg-myBg font-semibold uppercase text-gray-600 ">
                <tr>
                  {checkBox ? (
                    <th className="py-4">
                      <p className="p-2 whitespace-nowrap flex items-center w-1/2"></p>
                    </th>
                  ) : (
                    ""
                  )}

                  {tableColumns.map((item, index) => (
                    <th
                      key={index}
                      className="p-2 whitespace-nowrap font-semibold text-left"
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              {/* coupons */}

              <tbody className="text-sm  divide-gray-100">
                {searchText === "" ? (
                  <>
                    {/* .slice(pagesVisited, pagesVisited + itemsPerPage) */}
                    {data && data.length > 0 ? (
                      data.map((item, index) => (
                        <tr key={index}>
                          {checkBox ? (
                            <td
                              className={`text-left text-xs px-2 py-8 whitespace-nowrap  ${
                                index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                              }`}
                            >
                              <input
                                onChange={() => handleSelectedID(item._id)}
                                className="mx-2 cursor-pointer"
                                type="checkbox"
                              />
                            </td>
                          ) : (
                            ""
                          )}

                          <td
                            className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                              index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                            }`}
                          >
                            <p className={`text-left text-md `}>
                              {item.couponCode}
                            </p>
                          </td>

                          <td
                            className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                              index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                            }`}
                          >
                            <p className={`text-left text-md `}>
                              {item.couponPrice}
                            </p>
                          </td>
                          <td
                            className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                              index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                            }`}
                          >
                            <p className={`text-left text-md `}>
                              {item.isUsed ? "Used" : "Unused"}
                            </p>
                          </td>

                          <td
                            className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                              index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                            }`}
                          >
                            <p className={`text-left text-md `}>
                              {item.couponCount}
                            </p>
                          </td>
                          <td
                            className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                              index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                            }`}
                          >
                            <p className={`text-left text-md `}>
                              {item.isActive ? "Activated" : "Deactivated"}
                            </p>
                          </td>
                          <td
                            className={`text-left text-md px-2 py-8 whitespace-nowrap  ${
                              index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                            }`}
                          >
                            <button
                              onClick={() => {
                                // global.editId = item._id;
                                handleShowUpdate(item._id);
                              }}
                              className="py-2 px-4 bg-myBg text-xs rounded-lg"
                            >
                              View
                            </button>
                          </td>

                          {/* <Dropdown /> */}
                          <td
                            className={`text-left text-md px-2 py-8 whitespace-nowrap  ${
                              index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                            }`}
                          >
                            <CouponDropdown
                              isActive={item.isActive}
                              fetchCoupons={fetchCoupons}
                              itemId={item._id}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6}>
                          <div className="min-h-[150px] flex justify-center items-center w-full">
                            <h3 className="text-center text-xl w-full">
                              No available coupon.
                            </h3>
                          </div>
                        </td>
                      </tr>
                    )}
                    <CouponModal open={isOpen} onClose={modal}>
                      <CouponForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        isLoading={isLoading}
                        modalItem={modalItem}
                        rolesData={rolesData}
                      />
                    </CouponModal>
                  </>
                ) : (
                  <>
                    {myFilteredData.map((item, index) => (
                      <tr key={index}>
                        <td
                          className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-md `}>
                            {item.couponCode}
                          </p>
                        </td>

                        <td
                          className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-md `}>
                            {item.couponPrice}
                          </p>
                        </td>
                        <td
                          className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-md `}>
                            {item.isUsed ? "Used" : "Unused"}
                          </p>
                        </td>
                        <td
                          className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-md `}>
                            {item.couponCount}
                          </p>
                        </td>
                        <td
                          className={`text-left text-md px-2 py-8 whitespace-nowrap ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <p className={`text-left text-md `}>
                            {item.isActive ? "Activated" : "Deactivated"}
                          </p>
                        </td>
                        <td
                          className={`text-left text-md px-2 py-8 whitespace-nowrap  ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <button
                            onClick={() => {
                              // global.editId = item._id;
                              handleShowUpdate(item._id);
                            }}
                            className="py-2 px-4 bg-myBg text-xs rounded-lg"
                          >
                            View
                          </button>
                        </td>

                        {/* <Dropdown /> */}
                        <td
                          className={`text-left text-md px-2 py-8 whitespace-nowrap  ${
                            index % 2 !== 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <CouponDropdown
                            isActive={item.isActive}
                            fetchCoupons={fetchCoupons}
                            itemId={item._id}
                          />
                        </td>
                      </tr>
                    ))}
                    <CouponModal open={isOpen} onClose={modal}>
                      <CouponForm
                        handleSubmit={handleSubmit}
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        isLoading={isLoading}
                        modalItem={modalItem}
                        rolesData={rolesData}
                      />
                    </CouponModal>
                  </>
                )}
                {data && data.length === 0 && (
                  <p className="py-8 px-2 font-semibold">
                    No Records Found By This Key Word{" "}
                  </p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponsTable;

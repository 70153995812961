import cannabisForm from "../../assets/budbros-form.jpg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "rsuite/dist/rsuite.min.css";
import ImageHolder from "../../assets/upload.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { baseURL } from "../../constants/baseURL";
import {
  addCategory,
  updateCategory,
} from "../../redux/Actions/CategoryActions";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { axiosInstance } from "../../constants/axiosInstance";
import { Loader } from "rsuite";
import * as Yup from "yup";

const SignupSchema = (isAdd = true) =>
  Yup.object().shape({
    categoryPhoto: Yup.mixed().test("Image is required", (value) =>
      isAdd ? value != null && value instanceof File : true
    ),
    brand: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    category: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    subCategory: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    type: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

export const AddCategoriesForm = (props) => {
  const isAdd = typeof props.isAdd === "undefined" ? true : props.isAdd;
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filePreview, setFilePreview] = useState(null);
  const [editItem, setEditItem] = useState([]);
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [brandType, setBrandType] = useState([]);
  const [imgCheck, setImgCheck] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { categories } = useSelector((state) => state.categoryReducer);

  useEffect(() => {
    if (!isAdd) {
      setEditItem(
        categories.filter((category) => category._id === global.editId)
      );
      getCategory(
        categories.filter((category) => category._id === global.editId)
      );
    }
  }, []);

  function handleTags(value, type) {
    if (!value.trim()) return;

    let newTags;
    switch (type) {
      case "category":
        newTags = [...category, value.trim()];
        setCategory(newTags);
        break;
      case "subCategory":
        newTags = [...subCategory, value.trim()];
        setSubCategory(newTags);
        break;
      case "type":
        newTags = [...brandType, value.trim()];
        setBrandType(newTags);
        break;
      default:
        return;
    }

    // Directly updating Formik's field value
    Formik.setFieldValue(type, newTags.join(", "));
  }

  function removeTag(index, type) {
    if (type === "category") {
      setCategory(category.filter((el, i) => i !== index));
    }
    if (type === "subCategory") {
      setSubCategory(subCategory.filter((el, i) => i !== index));
    }
    if (type === "type") {
      setBrandType(brandType.filter((el, i) => i !== index));
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      return false;
    }
  }

  const getCategory = async (category) => {
    try {
      const res = await axiosInstance.get("/api/v1/category/getsinglebrand", {
        params: { brand: category[0].brand },
      });
      if (res.data.success) {
        setBrand(res.data.data.brand);
        setCategory(res.data.data.category);
        setSubCategory(res.data.data.subCategory);
        setBrandType(res.data.data.type);
      } else {
        alert.show("No Category Found");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const formData = new FormData();
    if (values.categoryPhoto instanceof File) {
      formData.append("categoryPhoto", values.categoryPhoto);
    }
    // formData.append("categoryPhoto", values.categoryPhoto);
    formData.append("brand", values.brand);
    formData.append("category", values.category);
    formData.append("subCategory", values.subCategory);
    formData.append("type", values.type);
    try {
      const action = isAdd ? addCategory : updateCategory;
      dispatch(action(values, formData, navigate, alert, props.modal));
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert.show("Error submitting the form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="w-full h-[85vh]">
      <div
        style={{ scrollbarWidth: "thin" }}
        className="container h-full mx-auto overflow-y-scroll"
      >
        <div className="flex justify-center">
          <div className="w-full flex ">
            <div
              className="w-2/3 h-auto  lg:block lg:w-5/12 bg-cover md:hidden "
              style={{
                backgroundImage: `linear-gradient( to right, rgba(0,0,0,0.2) ,rgba(0, 0, 0, 0.2)) ,url(${cannabisForm})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>

            <div className="w-full xl:w-[65%] md:w-full bg-white rounded-lg ">
              <h3 className="pt-4 text-2xl text-center mt-8 font-bold">
                {!isAdd ? "Category Details" : "Add Category"}
              </h3>
              <Formik
                enableReinitialize
                initialValues={{
                  categoryPhoto: editItem?.categoryPhoto || "",
                  brand: brand || "",
                  category: category.join(", "),
                  subCategory: subCategory.join(", "),
                  type: brandType.join(", "),
                }}
                validationSchema={SignupSchema(isAdd)}
                onSubmit={handleSubmit}
              >
                {({
                  isSubmitting,
                  values,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                }) => (
                  <Form
                    className="px-8 pt-6 pb-8 mb-4 my-10 bg-white rounded"
                    // onKeyDown={(event) => handleKeyDown(event)}
                  >
                    <div className="flex mx-auto justify-center">
                      <div className=" md:mr-2 md:mb-0 md:w-full justify-center mx-auto">
                        <label
                          htmlFor="upload"
                          className="w-[120px] h-[120px] block rounded-[50%] cursor-pointer mx-auto mb-2"
                        >
                          <img
                            className="w-[125px] h-[125px] block rounded-[50%] cursor-pointer mb-2 bg-neutral-50"
                            src={
                              !isAdd && editItem.length !== 0 && !imgCheck
                              ? `${editItem[0].categoryPhoto}?${new Date().getTime()}`
                              : values.categoryPhoto && !filePreview
                              ? `${values.categoryPhoto}?${new Date().getTime()}`
                              : ImageHolder
                            }                            
                            alt="img"
                          />

                          <input
                            className="hidden"
                            id="upload"
                            name="categoryPhoto"
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              setFieldValue("categoryPhoto", file);
                              setFilePreview(URL.createObjectURL(file));
                            }}
                          />
                        </label>

                        <label
                          className="block mb-2 text-sm font-bold text-gray-700 md:mt-2 text-center "
                          name="categoryPhoto"
                        >
                          Category Image
                        </label>
                        <ErrorMessage
                          className="text-red-600 text-xs text-center"
                          name="categoryPhoto"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="flex justify-around ">
                      <div className="flex flex-col my-5 mb-8">
                        <div className="flex flex-col justify-around my-3">
                          <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                            <div className=" md:mr-2 md:mb-0 md:w-full">
                              <label
                                className="block mb-2  text-sm font-bold text-gray-700 md:mt-2"
                                htmlFor="brand"
                              >
                                Brand
                              </label>
                              <Field
                                className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                type="text"
                                name="brand"
                              />
                              <ErrorMessage
                                className="text-red-600 text-xs font-thin"
                                name="brand"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-around flex-col ">
                          <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                            <div className=" md:mr-2 md:mb-0 md:w-full">
                              <label
                                className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                                htmlFor="category"
                              >
                                Category
                              </label>
                              <input
                                className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                type="text"
                                value={values.category}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleTags(e.target.value, "category");
                                    e.preventDefault(); // Prevent form submission
                                  }
                                }}
                                name="category"
                              />

                              <div className="w-full mt-1 flex items-center flex-wrap gap-1">
                                {category.map((tag, index) => (
                                  <div
                                    key={index}
                                    className="bg-[#Dad8d8] inline-flex  p-2 rounded-full"
                                  >
                                    <div className="text-gray-700">{tag}</div>
                                    <div
                                      onClick={() =>
                                        removeTag(index, "category")
                                      }
                                      className="bg-[#000] text-[#fff] rounded-[50%] w-[20px] h-[20px] flex justify-center items-center ml-2 cursor-pointer"
                                    >
                                      &times;
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <ErrorMessage
                                className="text-red-600 text-xs"
                                name="category"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col justify-around my-2">
                          <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                            <div className=" md:mr-2 md:mb-0 md:w-full">
                              <label
                                className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                                htmlFor="subCategory"
                              >
                                Sub-Category
                              </label>
                              <input
                                className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                type="text"
                                value={values.subCategory}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleTags(e.target.value, "subCategory");
                                    e.preventDefault(); // Prevent form submission
                                  }
                                }}
                                name="subCategory"
                              />
                              <div className="w-full mt-1 flex items-center flex-wrap gap-1">
                                {subCategory.map((tag, index) => (
                                  <div
                                    key={index}
                                    className="bg-[#Dad8d8] inline-flex  p-2 rounded-full"
                                  >
                                    <div className="text-gray-700">{tag}</div>
                                    <div
                                      onClick={() =>
                                        removeTag(index, "subCategory")
                                      }
                                      className="bg-[#000] text-[#fff] rounded-[50%] w-[20px] h-[20px] flex justify-center items-center ml-2 cursor-pointer"
                                    >
                                      &times;
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <ErrorMessage
                                className="text-red-600 text-xs font-thin"
                                name="subCategory"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col justify-around  my-2">
                          <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                            <div className=" md:mr-2 md:mb-0 md:w-full">
                              <label
                                className="block mb-2 text-sm mt-2 font-bold text-gray-700 md:mt-2"
                                htmlFor="type"
                              >
                                Type
                              </label>
                              <input
                                className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                type="text"
                                value={values.type}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleTags(e.target.value, "type");
                                    e.preventDefault(); // Prevent form submission
                                  }
                                }}
                                name="type"
                              />
                              <div className="w-full mt-1 flex items-center flex-wrap gap-1">
                                {brandType.map((tag, index) => (
                                  <div
                                    key={index}
                                    className="bg-[#Dad8d8] inline-flex  p-2 rounded-full"
                                  >
                                    <div className="text-gray-700">{tag}</div>
                                    <div
                                      onClick={() => removeTag(index, "type")}
                                      className="bg-[#000] text-[#fff] rounded-[50%] w-[20px] h-[20px] flex justify-center items-center ml-2 cursor-pointer"
                                    >
                                      &times;
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <ErrorMessage
                                className="text-red-600 text-xs"
                                name="type"
                                component="div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-6 flex items-center justify-center gap-2 sm:flex-col text-center">
                      <button
                        className="flex items-center justify-center cursor-pointer gap-2 w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {!isAdd ? "Update" : "Submit"}{" "}
                        {isSubmitting && <Loader />}
                      </button>
                      <button
                        className={`w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline ${
                          !isAdd ? "hidden" : ""
                        }`}
                        type="reset"
                      >
                        Reset
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

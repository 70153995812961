import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import AnalyticsCard from "./AnalyticsCard";

import { MdAdUnits } from "react-icons/md";
import { RiMoneyEuroCircleFill } from "react-icons/ri";
import { getCategories } from "../../redux/Actions/CategoryActions";
import { useSelector, useDispatch } from "react-redux";
import {
  getTotalStockByCategory,
  getTotalProductsSoldByDate,
  getTotalShippingChargesByDateRange,
} from "../../utils/revenue";

const StockRevenue = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categoryReducer);
  const [catSelect, setCatSelect] = useState("");
  const [loadStockRev, setLoadStockRev] = useState(false);
  const [categoryData, setCategoryData] = useState(0);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const handleGetCatData = async (e) => {
    setCatSelect(e.target.value);
    const payload = {
      category: e.target.value,
    };
    const stockRev = await getTotalStockByCategory(setLoadStockRev, payload);

    if (!loadStockRev) {
      setCategoryData(stockRev);
    }
  };

  // total products sold
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [loadingRange, setLoadingRange] = useState(false);
  const [rangeData, setRangeData] = useState(0);
  const handleChangeDatePicker = async (update) => {
    setDateRange(update);

    const payload = {
      dateRange: update,
    };
    const revenue = await getTotalProductsSoldByDate(setLoadingRange, payload);

    if (!loadingRange) {
      setRangeData(revenue);
    }
  };

  //  Total shipping charges
  const [shippingdateRange, setShippingDateRange] = useState([null, null]);
  const [shippingstartDate, shippingendDate] = shippingdateRange;
  const [loadingShippingRange, setLoadingShippingRange] = useState(false);
  const [shippingrangeData, setshippingRangeData] = useState(0);
  const handleChangeDatePickeforShipping = async (update) => {
    setShippingDateRange(update);

    const payload = {
      dateRange: update,
    };
    const revenue = await getTotalShippingChargesByDateRange(
      setLoadingShippingRange,
      payload
    );

    if (!loadingShippingRange) {
      setshippingRangeData(revenue);
    }
  };
  return (
    <>
      <AnalyticsCard
        icon={<MdAdUnits />}
        title="Total Stock by Category"
        data={categoryData}
        showFilter={true}
        optionsData={
          <>
            <option value="All" className="capitalize">
              All
            </option>
            {categories.map((cat, i) => (
              <option value={cat.category[0]} className="capitalize" key={i}>
                {cat.category[0]}
              </option>
            ))}
          </>
        }
        handleChange={handleGetCatData}
        value={catSelect}
        isLoading={loadStockRev}
      />

      <AnalyticsCard
        icon={<MdAdUnits />}
        title="Total Products Sold"
        data={rangeData}
        showFilter={false}
        showDatePicker={true}
        startDate={startDate}
        endDate={endDate}
        handleChange={handleChangeDatePicker}
        isLoading={loadingRange}
      />

      <AnalyticsCard
        icon={<RiMoneyEuroCircleFill />}
        title="Total Shipping Charges"
        data={shippingrangeData}
        showFilter={false}
        showDatePicker={true}
        startDate={shippingstartDate}
        endDate={shippingendDate}
        handleChange={handleChangeDatePickeforShipping}
        isIncome={true}
        isLoading={loadingShippingRange}
      />
    </>
  );
};

export default StockRevenue;

import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Loader } from "../minor-components/Loader";
import * as Yup from "yup";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAlert } from "react-alert";

const PointsSchema = Yup.object().shape({
  pointValue: Yup.string().required("Required"),
  referralAmount: Yup.string().required("Required"),
});

export default function Rules() {
  const alert = useAlert();
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pointsData, setPointsData] = useState([]);

  const handleGetPointsData = async () => {
    try {
      const response = await axiosInstance.get(
        "/api/v1/coupon/getReferralPointsValue"
      );

      console.log({ RESP: response.data.data });
      if (response.data.success) {
        setPointsData(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const initialValues = {
    pointValue: pointsData.length > 0 ? pointsData[0].pointsValue : "",
    referralAmount: pointsData.length > 0 ? pointsData[0].amountOfPurchase : "",
  };

  const handleCreatePoints = async (values) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.post(
        "/api/v1/coupon/setReferralPointsValue",
        {
          pointsValue: values.pointValue,
          amountOfPurchase: values.referralAmount,
        }
      );

      if (response.data.success) {
        alert.show("Points created successfully");
        handleGetPointsData();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert.show(error.message);
    }
  };

  const handleUpdatePoints = async (values) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.patch(
        "/api/v1/coupon/updateReferralPointsValue",
        {
          id: pointsData.length > 0 ? pointsData[0]._id : "",
          pointsValue: values.pointValue,
          amountOfPurchase: values.referralAmount,
        }
      );

      if (response.data.success) {
        alert.show("Points updated successfully");
        handleGetPointsData();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      alert.show(error.message);
    }
  };

  const handleSubmit = async (values) =>
    pointsData.length > 0
      ? handleUpdatePoints(values)
      : handleCreatePoints(values);

  useEffect(() => {
    handleGetPointsData();
  }, []);
  return (
    <div className="divide-y  divide-gray-100 bg-white rounded-lg mt-4 shadow-lg">
      <div className="px-5 py-4 my-0 flex flex-col items-start justify-between">
        <h2 className="font-semibold text-gray-800 text-lg">
          Referral Points Rule
        </h2>
      </div>
      <section className="w-full flex gap-5">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={PointsSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue, handleChange }) => {
            return (
              <Form className="px-8 pt-6 pb-8 mb-4  ">
                <div className="flex flex-col">
                  <div className="flex flex-col justify-around my-3">
                    <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                      <div className=" md:mr-2 md:mb-0 md:w-full">
                        <label
                          className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                          htmlFor="pointValue"
                        >
                          Points Value (€)
                        </label>
                        <Field
                          className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          type="number"
                          name="pointValue"
                          placeholder="Enter value of user points"
                        />
                        <ErrorMessage
                          className="text-red-600 text-xs"
                          name="pointValue"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                      <div className=" md:mr-2 md:mb-0 md:w-full">
                        <label
                          className="block mb-2 text-sm mt-2 font-bold text-gray-700 md:mt-2"
                          htmlFor="referralAmount"
                        >
                          Referral Points Eligibility Amount (€)
                        </label>
                        <Field
                          className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          type="number"
                          name="referralAmount"
                          placeholder="Enter required amount for referral"
                        />
                        <ErrorMessage
                          className="text-red-600 text-xs font-thin"
                          name="referralAmount"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex gap-2 sm:flex-col text-center">
                  <button
                    className="flex items-center justify-center cursor-pointer gap-2 w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {pointsData.length > 0 ? "Update Rule" : "Add Rule"}

                    {isLoading && <Loader />}
                  </button>
                  <button
                    className={`w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline ${
                      !status ? "hidden" : ""
                    }`}
                    type="reset"
                  >
                    Reset
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>

        <article className="w-[90%]  p-4">
          <p className="font-medium text-base my-3">
            A point is valued at{" "}
            <span className="font-semibold">
              €{pointsData.length > 0 ? pointsData[0].pointsValue : 0}
            </span>{" "}
          </p>
          <p className="font-medium text-base my-3">
            A referer is only awarded points when the referred user makes a
            purchase of{" "}
            <span className="font-semibold">
              €{pointsData.length > 0 ? pointsData[0].amountOfPurchase : 0}
            </span>{" "}
          </p>
        </article>
      </section>
    </div>
  );
}

import React, { useState } from "react";

const CouponUse = ({ setFieldValue, modalItem }) => {
  console.log({ modalItem });
  const [isIndividualUse, setIsIndividualUse] = useState(
    false ||
      (modalItem && modalItem.isIndividualUse
        ? modalItem.isIndividualUse
        : false)
  );

  const handleCheckboxChange = (e, setFieldValue) => {
    const isChecked = e.target.checked;
    setIsIndividualUse(isChecked);
    // setFieldValue("isIndividualUse", isChecked);
    if (isChecked) {
      setFieldValue("isIndividualUse", true);
    } else {
      setFieldValue("isIndividualUse", false);
    }
  };
  return (
    <div className="flex items-center mb-2 mt-5">
      <input
        type="checkbox"
        id="allProducts"
        className="mr-2"
        checked={isIndividualUse}
        onChange={(e) => handleCheckboxChange(e, setFieldValue)}
      />
      <label
        htmlFor="allProducts"
        className="text-sm font-normal text-gray-700"
      >
        Individual use only
      </label>
    </div>
  );
};

export default CouponUse;

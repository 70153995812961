import { DashCard } from "../minor-components/DashCard";
import dashCardOrder from "../../assets/dash-card-order.png";
import dashCardCart from "../../assets/dash-card-cart.png";
import dashCardPending from "../../assets/dash-card-pending.png";
import dashCardCannabis from "../../assets/dash-card-budbros.png";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectProgressBarState } from "../../redux/Actions/ProgressBarActions";
import { axiosInstance } from "../../constants/axiosInstance";
import { DashMap } from "../minor-components/DashMap";
import { Modal } from "../minor-components/Modal";
import { Loader } from "../minor-components/Loader";
import { useAlert } from "react-alert";

const DashboardHeroSection = (props) => {
  const token = useSelector((state) => state.ProfileReducer);
  const alert = useAlert();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ProgressBarReducer);

  const [cardData, setCardData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [placesArr, setPlacesArr] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [link, setLink] = useState("");
  const [purpose, setPurpose] = useState("");
  const [coordinates, setCoordinates] = useState([]);

  useEffect(() => {
    if (token) {
      loadUsers();
      getRadius();
      loadDashboardData();
    }
  }, [token]);

  const getRadius = async () => {
    try {
      dispatch(selectProgressBarState(true));
      const res = await axiosInstance.get("/api/v1/admin/getradius", {
        headers: {
          Authorization: token,
        },
      });
      if (res.data.success) {
        dispatch(selectProgressBarState(false));
        setPlacesArr(res.data.data);
      } else {
        dispatch(selectProgressBarState(false));
        alert.show("No Radius Found");
      }
    } catch (e) {
      dispatch(selectProgressBarState(false));
      console.log(e);
    }
  };

  const loadDashboardData = async () => {
    try {
      dispatch(selectProgressBarState(true));
      const res = await axiosInstance.get("/api/v1/admin/getdashboarddata", {
        headers: {
          Authorization: token,
        },
      });
      if (res.data.success) {
        setCardData(res.data.data);
        dispatch(selectProgressBarState(false));
      } else {
        dispatch(selectProgressBarState(false));
        alert.show("Not Found");
      }
    } catch (e) {
      dispatch(selectProgressBarState(false));
      console.log({ e });
    }
  };

  const loadUsers = async () => {
    try {
      dispatch(selectProgressBarState(true));
      const res = await axiosInstance.get("/api/v1/user/getallusers", {
        headers: {
          Authorization: token,
        },
      });
      if (res.data.success) {
        setUserData(res.data.data);
        dispatch(selectProgressBarState(false));
      } else {
        dispatch(selectProgressBarState(false));
        alert.show("Not Found");
      }
    } catch (e) {
      dispatch(selectProgressBarState(false));
      console.log(e);
    }
  };

  const handleGenerateLink = async (purpose) => {
    setLink("");
    try {
      const response = await axiosInstance.post(
        "/api/v1/tokens/generate",
        { purpose },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const newToken = response.data.token;
      const generatedLink = `https://budbros.to/${purpose}?token=${newToken}`;
      setLink(generatedLink);
      setOpenLink(true);
    } catch (error) {
      console.error("Error generating token:", error);
      alert.show("Failed to generate link. Please try again.");
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert.show("Link copied to clipboard!");
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <>
      {!loading ? (
        <div className="bg-gray-50 z-0">
          <div className=" mt-24 bg-gray-50 ml-[20%] w-[78%]">
            <div className="m-0 p-0">
              <div className="pt-4">
                <h1 className="text-3xl mx-0 px-0">Dashboard</h1>
                <p className="text-xs ml-1">Dashboard</p>
              </div>
              <div className="flex items-center w-full flex-wrap justify-between py-4 px-1">
                <DashCard
                  bg="bg-red-100"
                  header={"Total Orders"}
                  data={cardData[0]?.totalAllOrders}
                  icon={dashCardOrder}
                  footer={cardData[0]?.last24HoursAllOrders}
                />
                <DashCard
                  bg="bg-lime-100"
                  header={"Pending Orders"}
                  data={cardData[1]?.totalPendingOrders}
                  icon={dashCardPending}
                  footer={cardData[1]?.last24HoursPendingOrders}
                />
                <DashCard
                  bg="bg-sky-200"
                  header={"Completed Orders"}
                  data={cardData[2]?.totalCompletedOrders}
                  icon={dashCardCart}
                  footer={cardData[2]?.last24HoursCompletedOrders}
                />
                <DashCard
                  bg="bg-green-100"
                  header={"Total Products"}
                  data={cardData[3]?.totalAddedProducts}
                  icon={dashCardCannabis}
                  footer={cardData[3]?.last24HoursAddedProducts}
                />
              </div>

              <div className="px-4">
                {/* <Table /> */}
                {/* <ActionsTable /> */}
                {coordinates.length > 0 ? (
                  <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                    <DashMap coordinates={coordinates} />
                  </Modal>
                ) : null}
              </div>
              {/* Auth Link Generation */}
              <div className=" bg-white py-4 px-4 my-4 rounded-lg  shadow-lg divide-y  divide-gray-100">
                <div className="h-10 my-0 flex flex-col items-start justify-between mb-4">
                  <h2 className="font-semibold text-gray-800 text-lg">
                    Generate Authentication Link
                  </h2>
                  <p className="text-xs">Link Generation</p>
                </div>
                <div className="flex gap-4 items-center pt-4 px-3">
                  <button
                    type="button"
                    onClick={() => {
                      handleGenerateLink("sign-up");
                      setPurpose("sign-up");
                    }}
                    className="py-2 px-4 bg-myBg text-xs font-semibold rounded-lg hover:bg-[#efca37] cursor-pointer"
                  >
                    Generate Signup Link
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleGenerateLink("login");
                      setPurpose("login");
                    }}
                    className="py-2 px-4 bg-myBg text-xs font-semibold rounded-lg hover:bg-[#efca37] cursor-pointer"
                  >
                    Generate Login Link
                  </button>
                </div>
                <div className="bg-white p-3 shadow-sm rounded-sm mt-4">
                  <div className="text-gray-700">
                    <div className="grid md:grid-cols-2 text-sm">
                      <div className="grid grid-cols-2">
                        <div className="py-2 font-semibold capitalize">
                          Generated {purpose} link
                        </div>
                        <input value={link} readOnly className="px-4 py-2" />
                      </div>
                      <button
                        onClick={handleCopy}
                        className="py-2 px-4 bg-myBg text-xs rounded-lg hover:bg-[#efca37] cursor-pointer font-semibold"
                      >
                        Copy Link
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Shop */}
              {/* <div className=" bg-white py-4 px-4 my-4 rounded-lg  shadow-lg divide-y  divide-gray-100">
                <div className="h-10 my-0 flex flex-col items-start justify-between mb-4">
                  <h2 className="font-semibold text-gray-800 text-lg">
                    Shop Address
                  </h2>
                  <p className="text-xs">Details</p>
                </div>
                {placesArr.length < 1 ? (
                  <h2>No Shop Found</h2>
                ) : (
                  <div className="w-full  mx-auto bg-white shadow-lg rounded-sm ">
                    <div className="py-3 ">
                      <div className="overflow-x-auto ">
                        <table className="table-auto w-full ">
                          <thead className="text-sm w-full h-14 bg-myBg font-semibold uppercase text-gray-600 ">
                            <tr>
                              <th
                                key={5}
                                className="p-2 whitespace-nowrap font-semibold text-left"
                              >
                                Shop Name
                              </th>
                              <th
                                key={1}
                                className="p-2 whitespace-nowrap font-semibold text-left"
                              >
                                Address
                              </th>
                              <th
                                key={6}
                                className="p-2 whitespace-nowrap font-semibold text-left"
                              >
                                Delivery Charges
                              </th>
                              <th
                                key={3}
                                className="p-2 whitespace-nowrap font-semibold text-left"
                              >
                                Map
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-sm  divide-gray-100">
                            {placesArr.map((item, index) => {
                              return item ? (
                                <tr key={index}>
                                  <td
                                    className={`text-left  px-2 py-8 whitespace-nowrap ${
                                      index % 2 !== 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }`}
                                  >
                                    <p className={`text-left text-md `}>
                                      {" "}
                                      {item.shopName}
                                    </p>
                                  </td>
                                  <td
                                    className={`text-left  px-2 py-8 whitespace-nowrap ${
                                      index % 2 !== 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }`}
                                  >
                                    <p className={`text-left text-md `}>
                                      {" "}
                                      {item.formattedAddress}
                                    </p>
                                  </td>
                                  <td
                                    className={`text-left  px-2 py-8 whitespace-nowrap ${
                                      index % 2 !== 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }`}
                                  >
                                    <p className={`text-left text-md `}>
                                      {" "}
                                      ${item.delivery}
                                    </p>
                                  </td>
                                  <td
                                    className={`text-left  px-2 py-8 whitespace-nowrap ${
                                      index % 2 !== 0
                                        ? "bg-white"
                                        : "bg-gray-50"
                                    }`}
                                  >
                                    <p className={`text-left text-md `}>
                                      <button
                                        onClick={() => {
                                          setCoordinates(
                                            item.geometry.coordinates
                                          );
                                          setIsOpen(true);
                                        }}
                                        className="py-2 px-4 bg-myBg text-xs rounded-lg hover:bg-[#efca37]"
                                      >
                                        View on map
                                      </button>
                                    </p>
                                  </td>
                                </tr>
                              ) : (
                                <>
                                  <h2>No Restaurant Found</h2>{" "}
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default DashboardHeroSection;

import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Loader } from "../minor-components/Loader";
import { Switch } from "@headlessui/react";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { axiosInstance } from "../../constants/axiosInstance";
import { useAlert } from "react-alert";
import { StandaloneSearchBox } from "@react-google-maps/api";

const ZoneSchema = Yup.object().shape({
  zoneName: Yup.string().required("Required"),
  zoneRegion: Yup.array(),
  shippingMethod: Yup.string(),
});

export default function ShippingZones({ isLoaded }) {
  const alert = useAlert();
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [zoneName, setZoneName] = useState("");
  const [regionsData, setRegionsData] = useState({});
  const [loadingRegions, setLoadingRegions] = useState(false);
  const [shippingMethods, setShippingMethods] = useState([
    {
      id: 1,
      title: "Free Shipping",
      enabled: false,
      description:
        "Free shipping is a special method which can be triggered with coupons and minimum spends.",
    },
  ]);

  const [zoneRegions, setZoneRegions] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleZoneNameChange = (event) => {
    setZoneName(event.target.value);
  };

  const handleOnPlacesChanged = () => {
    const address = inputRef.current.getPlaces();
    console.log(address);

    const addressObject = {};
    address[0].address_components.forEach((component) => {
      const { types, long_name } = component;
      if (types.includes("sublocality_level_2"))
        addressObject.sublocal2 = long_name;
      if (types.includes("sublocality_level_1"))
        addressObject.sublocal = long_name;
      if (types.includes("locality")) addressObject.city = long_name;
      if (types.includes("administrative_area_level_2"))
        addressObject.state = long_name;
      if (types.includes("postal_code")) addressObject.zip = long_name;
      if (types.includes("country")) addressObject.country = long_name;
    });

    const formattedAddress =
      `${addressObject.sublocal2} ${addressObject.sublocal} ${addressObject.city}, ${addressObject.zip}${addressObject.state}, ${addressObject.country}`.trim();
    // setZoneName(address[0].formatted_address);
    setZoneName(formattedAddress);
  };

  const handleEditMethod = (methodId) => {
    console.log("Editing method:", methodId);
    // Place for a modal or redirect to editing form
  };

  const handleToggleEnabled = (methodId, enabled) => {
    const updatedMethods = shippingMethods.map((method) =>
      method.id === methodId ? { ...method, enabled: enabled } : method
    );

    setShippingMethods(updatedMethods);
  };

  const handleDeleteMethod = (methodId) => {
    setShippingMethods(
      shippingMethods.filter((method) => method.id !== methodId)
    );
  };

  const initialValues = {
    zoneName: regionsData ? regionsData.zoneName : "",
    zoneRegion: "",
    shippingMethod: regionsData ? regionsData.shippingMethod : "",
  };

  const handleAddZones = () => {
    if (zoneName.trim() !== "") {
      setZoneRegions((prev) => [...prev, zoneName]);
      setRegionsData((prev) => ({
        ...prev,
        regions: [...prev.regions, zoneName],
      }));
    }

    setZoneName("");
  };

  const handleSubmit = async (values) => {
    return !isUpdate ? handleCreateZone(values) : handleUpdate(values);
  };

  const handleCreateZone = async (values) => {
    try {
      setIsLoading(true);
      const payload = {
        zoneName: values.zoneName,
        zoneRegion: zoneRegions,
        shippingMethod:
          shippingMethods.find((method) => method.enabled === true)?.title ||
          "",
      };
      const response = await axiosInstance.post(
        "/api/v1/zones/addShippingZones",
        {
          ...payload,
        }
      );

      if (response.data.success) {
        alert.show("Zones added successfully");
        fetchAllZones();
      }
      setIsLoading(false);
    } catch (error) {
      alert.show(error.message);
    }
  };

  const handleUpdate = async (values) => {
    try {
      setIsUpdate(true);
      setIsLoading(true);
      const payload = {
        id: regionsData._id,
        zoneName: values.zoneName,
        regions: zoneRegions,
        shippingMethod:
          shippingMethods.find((method) => method.enabled === true)?.title ||
          "",
      };

      const response = await axiosInstance.patch(
        "/api/v1/zones/updateShippingZone",
        {
          ...payload,
        }
      );

      if (response.data.success) {
        alert.show("Zones updated successfully");
        fetchAllZones();
      }
      setIsLoading(false);
    } catch (error) {
      alert.show(error.message);
    }
  };

  const fetchAllZones = async () => {
    try {
      setLoadingRegions(true);
      const response = await axiosInstance.get("/api/v1/zones/getAllZones");

      console.log("RESPONSE:", response.data.data[0]);

      if (response.data.success) {
        setRegionsData(response.data.data[0]);
        setZoneRegions(response.data.data[0].regions);
        setLoadingRegions(false);
        setIsUpdate(true);
      }
    } catch (error) {}
  };

  const handleDeleteZoneFromState = async (region) => {
    try {
      const filterZones =
        regionsData.regions.length > 0
          ? regionsData.regions.filter(
              (item) => item.toLowerCase() !== region.toLowerCase()
            )
          : zoneRegions.filter(
              (item) => item.toLowerCase() !== region.toLowerCase()
            );

      setZoneRegions(filterZones);
      setRegionsData(filterZones);

      const response = await axiosInstance.post(
        "/api/v1/zones/deleteZoneByName",
        {
          id: regionsData._id,
          region,
        }
      );

      if (response.data.success) {
        alert.show("Deleted region successfully!");
        fetchAllZones();
      }
    } catch (error) {
      alert.show(error.message);
    }
  };

  useEffect(() => {
    fetchAllZones();
  }, []);

  useEffect(() => {
    if (regionsData.shippingMethod) {
      const updatedMethods = shippingMethods.map((method) =>
        method.title.toLowerCase() === regionsData.shippingMethod.toLowerCase()
          ? { ...method, enabled: true }
          : { ...method, enabled: false }
      );
      setShippingMethods(updatedMethods);
    }
  }, [regionsData.shippingMethod]);

  return (
    <section className="divide-y  divide-gray-100 bg-white rounded-lg mt-4 shadow-lg">
      <div className="px-5 py-4 my-0 flex flex-col items-start justify-between">
        <h2 className="font-semibold text-gray-800 text-lg">Shipping Zones</h2>
      </div>

      <div className="flex gap-5 p-8">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={ZoneSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue, handleChange }) => {
            return (
              <Form className=" mb-4  ">
                <div className="flex flex-col">
                  <div className="flex flex-col justify-around my-3">
                    <div className=" md:flex md:justify-between w-60 md:w-full md:mb-0">
                      <div className=" md:mr-2 md:mb-0 md:w-full">
                        <label
                          className="block text-sm font-bold text-gray-700 md:mt-2"
                          htmlFor="zone-name"
                        >
                          Zone name
                        </label>
                        <p className="text-xs mb-2">Give your zone a name!</p>
                        <Field
                          className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                          type="text"
                          name="zoneName"
                          placeholder="Zone name"
                          onChange={(e) => handleChange(e)}
                        />
                        <ErrorMessage
                          className="text-red-600 text-xs"
                          name="zoneName"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className=" md:flex md:justify-between w-60  md:w-full md:mb-0">
                      <div className=" md:mr-2 md:mb-0 md:w-full">
                        <label
                          className="block text-sm mt-2 font-bold text-gray-700 md:mt-2"
                          htmlFor="zone-regions"
                        >
                          Zone regions
                        </label>
                        <p className="text-xs mb-2">
                          List the regions you'd like to include in your
                          shipping zone. Customers will be matched against these
                          regions.
                        </p>
                        {isLoaded && (
                          <StandaloneSearchBox
                            onPlacesChanged={handleOnPlacesChanged}
                            onLoad={(ref) => (inputRef.current = ref)}
                          >
                            <div className="w-fit relative">
                              <Field
                                className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline "
                                type="text"
                                name="region"
                                placeholder="Enter regions"
                                value={zoneName}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleZoneNameChange(e);
                                }}
                              />

                              <button
                                className="text-[#E9C95D] bg-white text-xs capitalize absolute top-1/2 -translate-y-1/2 right-2"
                                onClick={handleAddZones}
                                type="button"
                              >
                                add
                              </button>
                            </div>
                          </StandaloneSearchBox>
                        )}
                        <ErrorMessage
                          className="text-red-600 text-xs font-thin"
                          name="region"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className=" md:flex md:justify-between w-full md:w-full md:mb-0">
                      <div className=" md:mr-2 md:mb-0 md:w-full">
                        <label
                          className="block text-sm mt-2 font-bold text-gray-700 md:mt-2"
                          htmlFor="shipping-methods"
                        >
                          Shipping Methods
                        </label>
                        <p className="text-xs mb-2">
                          Add the shipping methods you'd like to make available
                          to customers in this zone.
                        </p>
                        {shippingMethods.map((method) => (
                          <div key={method.id} className="mb-4">
                            <h3 className="font-semibold text-sm">
                              {method.title}
                            </h3>
                            <p className="text-xs">{method.description}</p>
                            <div className="flex items-center mt-2">
                              <label
                                htmlFor={`toggle-${method.id}`}
                                className="mr-2"
                              >
                                Enabled:
                              </label>
                              <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                <Switch
                                  checked={method.enabled}
                                  onChange={(newEnabled) =>
                                    handleToggleEnabled(method.id, newEnabled)
                                  }
                                  className={`group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[#E9C95D] focus:ring-offset-2 data-[checked]:bg-[#E9C95D] ${
                                    method.enabled && "bg-indigo-400"
                                  }`}
                                >
                                  <span className="sr-only">
                                    Enable shippingMethods
                                  </span>
                                  <span
                                    className={`pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5 ${
                                      method.enabled && "translate-x-5"
                                    }`}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out "
                                    >
                                      <svg
                                        fill="none"
                                        viewBox="0 0 12 12"
                                        className="h-3 w-3 text-gray-400"
                                      >
                                        <path
                                          d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <span
                                      aria-hidden="true"
                                      className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
                                    >
                                      <svg
                                        fill="currentColor"
                                        viewBox="0 0 12 12"
                                        className="h-3 w-3 text-indigo-600"
                                      >
                                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                      </svg>
                                    </span>
                                  </span>
                                </Switch>
                              </div>
                            </div>
                            <button
                              type="button"
                              onClick={() => handleEditMethod(method.id)}
                              className="text-blue-500 hover:text-blue-700 font-bold cursor-pointer"
                            >
                              Edit
                            </button>
                            <span className="mx-2">|</span>
                            <button
                              type="button"
                              onClick={() => handleDeleteMethod(method.id)}
                              className="text-red-500 hover:text-red-700  font-bold cursor-pointer"
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                        {/* <ErrorMessage
                        className="text-red-600 text-xs font-thin"
                        name="brand"
                        component="div"
                      /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex gap-2 sm:flex-col text-center">
                  {regionsData.regions?.length > 0 ? (
                    <button
                      className="flex items-center justify-center cursor-pointer gap-2 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline w-fit "
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Update Shipping Method
                      {isLoading && <Loader />}
                    </button>
                  ) : (
                    <button
                      className="flex items-center justify-center cursor-pointer gap-2 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline w-fit "
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Add Shipping Method
                      {isLoading && <Loader />}
                    </button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>

        <ul className="regions border border-gray-100 py-2 max-w-[400px] w-full max-h-[200px] overflow-y-auto px-2 rounded-md flex flex-wrap gap-2 items-start">
          {!loadingRegions && (
            <>
              {regionsData && regionsData.regions?.length > 0 ? (
                <>
                  {regionsData.regions.map((zones, i) => (
                    <li
                      className="flex gap-2 items-center justify-between px-3 py-1 bg-gray-200 rounded-3xl"
                      key={i}
                    >
                      <span className="text-xs capitalize">{zones}</span>
                      <button
                        className="text-xs"
                        onClick={() => handleDeleteZoneFromState(zones)}
                      >
                        <FontAwesomeIcon icon="times" />
                      </button>
                    </li>
                  ))}
                </>
              ) : (
                zoneRegions.map((zones, i) => (
                  <li
                    className="flex gap-2 items-center justify-between px-3 py-1 bg-gray-200 rounded-3xl"
                    key={i}
                  >
                    <span className="text-xs capitalize">{zones}</span>
                    <button
                      className="text-xs"
                      onClick={() => handleDeleteZoneFromState(zones)}
                    >
                      <FontAwesomeIcon icon="times" />
                    </button>
                  </li>
                ))
              )}
            </>
          )}
        </ul>
      </div>
    </section>
  );
}

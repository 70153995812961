import React, { useState } from "react";
import Select from "react-select";
import { ErrorMessage } from "formik";

const CouponType = ({ setFieldValue, modalItem }) => {
  const [isFreeShipping, setIsFreeShipping] = useState(
    false ||
      (modalItem &&
        modalItem.couponType &&
        modalItem.couponType === "Free Shipping")
  );
  const options = [
    { value: "Fixed Cart Discount", label: "Fixed Cart Discount" },
    { value: "Percentage Discount", label: "Percentage Discount" },
  ];

  const handleCheckboxChange = (e, setFieldValue) => {
    const isChecked = e.target.checked;
    setIsFreeShipping(isChecked);
    if (isChecked) {
      setFieldValue("couponType", "Free Shipping");
      setFieldValue("couponPrice", "0");
    } else {
      setFieldValue("couponType", "");
    }
  };

  return (
    <div className=" md:mr-2 md:mb-0 md:w-full">
      <label
        className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
        htmlFor="couponType"
      >
        Coupon Type
      </label>

      <Select
        closeMenuOnSelect={false}
        className="w-full shadow mb-3"
        name="couponType"
        defaultValue={
          modalItem &&
          modalItem.couponType &&
          options.find(
            (data) =>
              data.value.toLowerCase() === modalItem.couponType.toLowerCase()
          )
        }
        options={options}
        onChange={(option) => setFieldValue("couponType", option.value)}
        isDisabled={isFreeShipping}
      />
      <ErrorMessage
        className="text-red-600 text-xs"
        name="couponType"
        component="div"
      />

      <div className="flex items-center mb-2">
        <input
          type="checkbox"
          id="allProducts"
          className="mr-2"
          checked={isFreeShipping}
          onChange={(e) => handleCheckboxChange(e, setFieldValue)}
        />
        <label
          htmlFor="allProducts"
          className="text-sm font-normal text-gray-700"
        >
          Free Shipping
        </label>
      </div>
    </div>
  );
};

export default CouponType;

import React from "react";
import Rules from "../minor-components/Rules";
import Pricing from "../minor-components/Pricing";
import ShippingZones from "../minor-components/ShippingZones";

export default function Settings({ isLoaded }) {
  //  functions

  return (
    <div className="bg-gray-50 z-0">
      <div className=" mt-24 bg-gray-50 ml-[20%] w-[78%]">
        <div className="m-0 p-0">
          <div className="pt-4">
            <h1 className="text-3xl mx-0 px-0">Settings</h1>
            {/* <p className="text-xs ml-1">Dashboard</p> */}
          </div>
          {/* Rule */}
          <Rules />
          {/* Pricing */}
          <Pricing />
          {/* Shipping Zones */}
          <ShippingZones isLoaded={isLoaded} />
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import topArrow from "../../assets/top-arrow.svg";
import bottomArrow from "../../assets/bottom-arrow.svg";
import ReactPaginate from "react-paginate";
import CouponsTable from "../minor-components/CouponsTable";
import { useAlert } from "react-alert";
import { axiosInstance } from "../../constants/axiosInstance";

export const Coupons = () => {
  const [data, setData] = useState([]);
  const alert = useAlert();
  const [isOpen, setIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const pagesVisited = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(data?.length / itemsPerPage);
  const [searchText, setSearchText] = useState("");
  const [myFilteredData, setMyFilteredData] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [modalItem, setModalItem] = useState({});

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
  };

  const handleSelectedID = (id) => {
    if (selectedID.includes(id)) {
      let filteredIDS = selectedID.filter((toBe) => toBe !== id);
      setSelectedID(filteredIDS);
    } else {
      setSelectedID([...selectedID, id]);
    }
  };

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(data);
    else {
      const filteredData =
        data &&
        data.length > 0 &&
        data
          // .slice(pagesVisited, pagesVisited + itemsPerPage)
          .filter((item) => {
            return Object.keys(item).some((key) =>
              item[key].toString().toLowerCase().includes(lowercasedValue)
            );
          });
      setData(filteredData);
      setMyFilteredData(filteredData);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleFetchAllCoupons = async () => {
    try {
      const response = await axiosInstance.get("/api/v1/coupon/getAllCoupons");

      if (response.data.success) {
        setData(response.data.data);
      }
    } catch (error) {}
  };

  const handleDeleteCoupon = async (id) => {
    try {
      id.forEach(async (item) => {
        const response = await axiosInstance.delete(`/api/v1/coupon/${item}`);

        if (response.data.success) {
          alert.show("Coupon deleted successfully!");
          handleFetchAllCoupons();
        }
      });
    } catch (error) {
      alert.show("An error occurred");
    }
  };

  useEffect(() => {
    handleFetchAllCoupons();
  }, []);

  return (
    <>
      <section className="bg-gray-50 z-0 pt-5">
        <div className=" mt-24 bg-gray-50 ml-[20%]  w-[78%] ">
          <div className="flex items-center justify-end py-4 px-4">
            <button
              onClick={() => {
                setIsOpen(true);
                setIsUpdate(false);
                setModalItem({});
              }}
              className="bg-myBg text-gray-600 px-4 py-2 cursor-pointer hover:bg-[#E9D95D]"
            >
              Add Coupon
            </button>
          </div>

          <div className="py-2 bg-white shadow-lg rounded-lg mt-5">
            <div className="border-b border-b-gray-100 bg-white  ">
              <article className="px-5 pb-2 pt-4 my-0">
                <h2 className="font-semibold text-gray-800 text-lg">Coupons</h2>
                <p className="text-xs">Details</p>
              </article>
            </div>

            <section className="w-full mt-4 px-5">
              <div className="flex items-start justify-between">
                <div className="flex items-center justify-start gap-2 h-12">
                  <h2 className="text-xs">Show</h2>
                  <div className="flex w-20">
                    <input
                      type="text"
                      value={""}
                      disabled={true}
                      className="bg-white text-sm text-gray-900 text-center focus:outline-none border border-gray-800 focus:border-gray-600 rounded-sm w-full h-8 "
                    />
                    <div className="flex flex-col items-center gap-2 justify-center ml-[-15px]">
                      <img
                        onClick={(e) =>
                          setItemsPerPage(
                            itemsPerPage < data.length
                              ? itemsPerPage + 1
                              : itemsPerPage
                          )
                        }
                        className="w-2 cursor-pointer"
                        src={topArrow}
                        alt="top-arrow"
                      />
                      <img
                        onClick={(e) =>
                          setItemsPerPage(
                            itemsPerPage > 5 ? itemsPerPage - 1 : itemsPerPage
                          )
                        }
                        className="w-2 cursor-pointer"
                        src={bottomArrow}
                        alt="bottom-arrow"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-around gap-2">
                  <input
                    placeholder="Type to search..."
                    value={searchText}
                    onChange={(e) => handleChange(e.target.value)}
                    type="text"
                    className="
                                        bg-white text-sm text-gray-900 text-center 
                                        focus:outline-none border border-gray-800 focus:border-gray-600 
                                         rounded-sm w-18 h-8"
                  />
                  <div>
                    {!selectedID.length <= 0 ? (
                      <button
                        onClick={() => handleDeleteCoupon(selectedID)}
                        className="py-2 px-4 bg-myBg text-xs "
                      >
                        Delete Selected Coupon
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </section>

            <section className="w-full">
              <CouponsTable
                isOpen={isOpen}
                modal={handleCloseModal}
                openModal={handleOpenModal}
                fetchCoupons={handleFetchAllCoupons}
                alert={alert}
                data={data}
                setIsUpdate={setIsUpdate}
                isUpdate={isUpdate}
                modalItem={modalItem}
                setModalItem={setModalItem}
                handleSelectedID={handleSelectedID}
                myFilteredData={myFilteredData}
              />
            </section>

            {/* pagination */}
            <div className="flex items-center justify-between mt-6 mb-2 px-5">
              <p className="text-xs text-left">
                Showing{" "}
                {data &&
                  data.slice(pagesVisited, pagesVisited + itemsPerPage)
                    .length}{" "}
                results of {data && data.length}
              </p>
              <div className="flex items-center justify-center">
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={
                    "flex justify-between gap-3 items-center w-full text-xs rounded no-underline"
                  }
                  previousLinkClassName={
                    "py-2 px-4 rounded bg-gray-200 w-24 hover:bg-gray-300 mx-2 no-underline"
                  }
                  nextLinkClassName={
                    "py-2 px-4 rounded bg-gray-200 w-24 hover:bg-gray-300 mx-2 no-underline"
                  }
                  disabledClassName={"pointer-events-none no-underline"}
                  activeClassName={
                    "py-2 px-4 rounded bg-myBg hover:bg-[#edcb58] hover:text-white text-xs mx-2 no-underline"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


export const ViewUserDetails = ({ showDetails }) => {
  const user = showDetails;
  return (
    <>
      {showDetails ? (
        <>
          <div class="w-full h-[40vh] p-7">
            <div
              style={{ scrollbarWidth: "thin" }}
              className="container h-full mx-auto overflow-y-scroll"
            >
              <div className="flex justify-center">
                <div className="w-full flex flex-col">
                  <div className="w-full xl:w-[100%] md:w-full bg-white rounded-lg ">
                    <h3 className="pt-4 text-2xl mt-8 font-bold">
                      User Details
                    </h3>
                    <div className="bg-gray-100">
                      <div className=" mx-auto my-5 p-5">
                        <div className="md:flex no-wrap md:-mx-2 ">
                          <div className="w-full md:w-9/12 mx-2 h-auto">
                            <div className="bg-white p-3 shadow-sm rounded-sm">
                              <div className="text-gray-700">
                                <div className="grid md:grid-cols-2 text-sm">
                                  <div className="grid grid-cols-2">
                                    <div className="px-4 py-2 font-semibold">
                                      Username
                                    </div>
                                    <div className="px-4 py-2">
                                      {user.userName}
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-2">
                                    <div className="px-4 py-2 font-semibold">
                                      Chat ID
                                    </div>
                                    <div className="px-4 py-2">
                                      {user.chatId}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

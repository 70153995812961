import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../constants/axiosInstance";
import { Table } from "../minor-components/Table";
import { Loader } from "../minor-components/Loader";
import { useSelector, useDispatch } from "react-redux";
import io from "socket.io-client";
import { selectProgressBarState } from "../../redux/Actions/ProgressBarActions";

const ordersColumns = [
  "Order ID",
  "Product",
  "Variations",
  "Qty",
  "Total",
  "Address",
  "UserName",
  "ChatID",
  "Status",
  "Delivery",
];

export const Orders = () => {
  const token = useSelector((state) => state.ProfileReducer);
  const [forceReload, setForceReload] = useState(false);
  const [allOrders, setAllOrders] = useState([]);

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ProgressBarReducer);

  const getAllOrders = async () => {
    dispatch(selectProgressBarState(true));
    const orders = await axiosInstance.get(`/api/v1/order/getallordersadmin`, {
      headers: {
        Authorization: token,
      },
    });
    console.log({ allOrders: orders.data.data });
    if (orders.data.success) {
      let arrangedData = orders?.data?.data?.map((item) => {
        return {
          id: item._id,
          orderId: item.orderid,
          productName: item.details
            .map((item2) => {
              return item2.productId?.name;
            })
            .join(" / "),
          variations: item.details.map((data) => data.productVariations)[0],
          productQuantity: item.details
            .map((item2) => {
              return item2.quantity;
            })
            .join(" / "),
          totalPrice: item.totalPrice,
          address: item.address,
          userName: item.user.userName,
          chatId: item.user.chatId,
          status: item.status,
          deliveryStatus: item.deliveryStatus,
        };
      });
      setAllOrders(arrangedData);
      dispatch(selectProgressBarState(false));
    } else {
      dispatch(selectProgressBarState(false));
    }
  };

  useEffect(() => {
    if (token) {
      getAllOrders();
    }
  }, [forceReload, token]);

  useEffect(() => {
    const socket = io(process.env.NODE_LOCAL_SERVER);
    socket.on("newOrder", () => {
      setForceReload(!forceReload);
    });
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className={`py-8 bg-gray-50 min-h-screen`}>
        <div className={`bg-gray-50 ml-[20%]  w-[78%] mt-24 `}>
          <div className="bg-gray-50">
            {/* All Orders */}
            <div className=" mt-12">
              <div className="flex flex-col ">
                {allOrders.length !== 0 ? (
                  <Table
                    type={"orders"}
                    title={"All Orders"}
                    forceReload={forceReload}
                    setForceReload={setForceReload}
                    pendingOrders={true}
                    approvedOrders={false}
                    acceptedOrders={false}
                    completedOrders={false}
                    ordersColumns={ordersColumns}
                    ordersData={allOrders}
                  />
                ) : (
                  <div className="divide-y  divide-gray-100 bg-white rounded-lg  shadow-lg">
                    <div className="px-5 pt-4  h-10 my-0 flex flex-col items-start justify-between">
                      <h2 className="font-semibold text-gray-800 text-lg">
                        All Orders
                      </h2>
                      <p className="text-sm text-gray-500">Details</p>
                    </div>
                    <div className="flex justify-center items-center py-8 text-lg">
                      No Orders Found
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Pending Orders */}

            {/* Approved Orders */}

            {/* Accepted Orders */}

            {/* Completed Orders */}
          </div>
        </div>
      </div>
    </>
  );
};

import cannabisForm from "../../assets/budbros-form.jpg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Loader } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { updateProduct, addProduct } from "../../redux/Actions/ProductActions";
import { axiosInstance } from "../../constants/axiosInstance";
import defaultProductPhoto from "../../assets/defaultProductImage.jpeg";
import { baseURL } from "../../constants/baseURL";
import { FaTimes } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageHolder from "../../assets/upload.svg";

const ProductSchema = (rolesData, isAdd = true) =>
  Yup.object().shape({
    // productPhoto: Yup.mixed().test(
    //   "fileRequired",
    //   "Image is required",
    //   (value) => (isAdd ? value != null : true)
    // ),
    productPhoto: Yup.array()
      .of(Yup.mixed())
      .min(1, "At least one file is required")
      .max(4, "You can only upload 4 files")
      .test("requiredForAdd", "At least one file is required", (value) =>
        isAdd ? value && value.length > 0 : true
      ),
    id: Yup.string(),
    name: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    subCategory: Yup.string().required("Required"),
    brand: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    units: Yup.string().required("Required"),
    rolePrices: Yup.lazy((value) => {
      const schemaShape = rolesData.reduce((acc, role) => {
        acc[`${role.role}-price`] = Yup.string();
        return acc;
      }, {});

      return Yup.object().shape(schemaShape);
    }),
  });

export const AddProductsForm = (props) => {
  const status = !props.isAdd ? props.isAdd : true;
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filePreview, setFilePreview] = useState(null);
  const [brandField, setBrandField] = useState("");
  const [editItem, setEditItem] = useState([]);
  const [shops, setShops] = useState([]);
  const [categoryItems, setCategoryItems] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [brandType, setBrandType] = useState([]);
  const [imgCheck, setImgCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [rolesData, setRolesData] = useState([]);
  const [filesPreview, setFilesPreview] = useState([]);
  const [productsVariations, setProductVariations] = useState([]);
  const [variationInputs, setVariationInputs] = useState({
    variationName: "",
    variationPrice: "",
    variationStock: "",
  });
  let ShopsArray = [];

  const { products } = useSelector((state) => state.productReducer);

  const token = useSelector((state) => state.ProfileReducer);

  useEffect(() => {
    getCategory();
    if (!status) {
      setEditItem(products.filter((product) => product._id === global.editId));
      loadData();
    }
  }, []);

  useEffect(() => {
    if (editItem.length > 0 && editItem[0].productPhoto) {
      console.log("PRODUCT PHOTO:", editItem[0].productPhoto);
      const photos = editItem[0].productPhoto.map((photo) => {
        const splitPhoto = photo.split(".");

        let media = {
          url: photo,
          type: photo.endsWith(".mp4")
            ? "video/mp4"
            : `image/${splitPhoto[splitPhoto.length - 1]}`,
        };

        return media;
        // "video/mp4"
      });

      console.log(photos);
      // setFilesPreview(editItem[0].productPhoto); // Set the product photos for preview
      setFilesPreview(photos);
    }
    if (editItem.length > 0 && editItem[0].variations) {
      setProductVariations(editItem[0].variations);
    }
  }, [editItem]);

  const handleChangeVariationInputs = (e) => {
    const name = e.target.name;

    setVariationInputs((prev) => ({ ...prev, [name]: e.target.value }));
  };

  const handleAddVariations = () => {
    if (
      variationInputs.variationName ||
      variationInputs.variationPrice ||
      variationInputs.variationStock
    ) {
      const newdata = {
        name: variationInputs.variationName,
        price: Number(variationInputs.variationPrice),
        stock: Number(variationInputs.variationStock),
        isChecked: false,
      };
      setProductVariations((prev) => [...prev, newdata]);
    }
  };

  const initialValues = {
    productPhoto: editItem.length > 0 ? editItem[0].productPhoto : null,
    name: editItem.length !== 0 ? `${editItem[0].name}` : "",
    description: editItem.length !== 0 ? `${editItem[0].description}` : "",
    shopid: editItem.length !== 0 ? `${editItem[0].shopid}` : "",
    brand: brandField,
    category: editItem.length > 0 ? editItem[0].category : [],
    subCategory: editItem.length > 0 ? editItem[0].subCategory : [],
    type: editItem.length > 0 ? editItem[0].type : [],
    price: editItem.length !== 0 ? `${editItem[0].price}` : "",
    units: editItem.length !== 0 ? `${editItem[0].units}` : "",
    ...rolesData.reduce((acc, role) => {
      const rolePriceKey = `${role.role}-price`;
      acc[rolePriceKey] =
        editItem.length !== 0 && editItem[0].rolePrices
          ? editItem[0].rolePrices[role.role] || "" // Set price if available, otherwise empty string
          : ""; // Set empty if no editItem
      return acc;
    }, {}),
  };

  const loadData = async () => {
    let brandName = products.filter((product) => {
      return product.brand;
    });
    setBrandField(brandName[0].brand);
    axiosInstance
      .get("/api/v1/category/getsinglebrand", {
        params: { brand: brandName[0].brand },
      })
      .then((res) => {
        if (res.data.success) {
          setCategory(res.data.data.category);
          setSubCategory(res.data.data.subCategory);
          setBrandType(res.data.data.type);
        } else {
          alert.show("No Category Found");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCategory = async () => {
    try {
      const res = await axiosInstance.get("/api/v1/category/getcategories");

      if (res.data.success) {
        axiosInstance
          .get("/api/v1/admin/getradius", {
            headers: {
              Authorization: token,
            },
          })
          .then((response) => {
            if (response.data.success) {
              response.data.data.forEach(function (restu) {
                let obj = {
                  title: restu.shopName,
                  id: restu._id,
                  delivery: restu.delivery,
                };
                ShopsArray.push(obj);
              });
              setShops(ShopsArray);
              setCategoryItems(res.data.data);
            } else {
              alert.show("No Category Found");
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        alert.show("No Category Found");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleBrand = async (event, setFieldValue) => {
    event.preventDefault();

    try {
      setFieldValue("brand", event.target.value);
      axiosInstance
        .get("/api/v1/category/getsinglebrand", {
          params: {
            brand: event.target.value,
          },
        })
        .then((res) => {
          if (res.data.success) {
            setFieldValue("category", res.data.data.category[0]);
            setCategory(res.data.data.category);
            setFieldValue("subCategory", res.data.data.subCategory[0]);
            setSubCategory(res.data.data.subCategory);
            setFieldValue("type", res.data.data.type[0]);
            setBrandType(res.data.data.type);
          } else {
            alert.show("No Category Found");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (values) => {
    console.log("photoooo", values);
    setIsLoading(true);

    const formData = new FormData();

    const rolePrice = {};
    rolesData.forEach((role) => {
      // Append each role price to the form data
      const rolePriceKey = `${role.role}-price`;
      rolePrice[`${role.role}`] = Number(values[rolePriceKey]);
    });

    const photoUrlsArray = filesPreview
      .filter((file) => !file.url.includes("localhost"))
      .map((file) => file.url);

    if (Array.isArray(values.productPhoto)) {
      values.productPhoto.forEach((file) => {
        if (file instanceof File) {
          formData.append("productPhoto", file); // This will add multiple files under the same key
        }
      });
    }

    formData.append("id", editItem.length !== 0 ? editItem[0]._id : "");
    formData.append("name", values.name);
    formData.append("existingPhotos", JSON.stringify(photoUrlsArray));
    formData.append("brand", values.brand);
    formData.append("category", values.category);
    formData.append("subCategory", values.subCategory);
    formData.append("type", values.type);
    formData.append("price", values.price);
    formData.append("units", values.units);
    formData.append("description", values.description);
    formData.append("delivery", values.delivery);
    formData.append(`rolePrices`, JSON.stringify(rolePrice));
    formData.append("productVariations", JSON.stringify(productsVariations));

    try {
      console.log("here is working");
      const action = status ? addProduct : updateProduct;
      dispatch(action(values, formData, navigate, alert, props.modal));
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert.show("Error submitting the form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteVariation = async (name) => {
    try {
      const filteredVariations = productsVariations.filter(
        (variation) => name.toLowerCase() !== variation.name.toLowerCase()
      );

      if (editItem.length > 0 && editItem[0]._id) {
        const response = await axiosInstance.post(
          "/api/v1/product/deleteProductVariation",
          {
            id: editItem.length !== 0 ? editItem[0]._id : "",
            productVariations: filteredVariations,
          }
        );
        if (response.data.success) {
          setProductVariations(filteredVariations);
          alert.show(" Variation deleted successfully.");
        }
      } else {
        setProductVariations(filteredVariations);
      }
    } catch (error) {
      console.log(error);
      alert.show(error.message);
    }
  };

  const fetchAllRoles = async () => {
    try {
      setLoadingRoles(true);
      const response = await axiosInstance.get("/api/v1/role/getAllRoles");

      if (response.data.success) {
        setLoadingRoles(false);
        setRolesData(response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);

  const handleDeleteFile = (url) => {
    const filteredFiles = filesPreview.filter((file) => file.url !== url);

    setFilesPreview(filteredFiles);
  };

  return (
    <>
      <div className="w-full h-[85vh]">
        <div
          style={{ scrollbarWidth: "thin" }}
          className="container h-full mx-auto overflow-y-scroll"
        >
          <div className="flex justify-center">
            <div className="w-full flex ">
              <div
                className="w-2/3 h-auto  lg:block lg:w-5/12 bg-cover md:hidden "
                style={{
                  backgroundImage: `linear-gradient( to right, rgba(0,0,0,0.2) ,rgba(0, 0, 0, 0.2)) ,url(${cannabisForm})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>

              <div className="w-full xl:w-[65%] md:w-full bg-white rounded-lg ">
                <h3 className="pt-4 text-2xl text-center mt-8 font-bold">
                  {!status ? "Product Details" : "Add Product"}
                </h3>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={ProductSchema(rolesData, !status)}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, values, setFieldValue, handleChange }) => {
                    return (
                      <Form className="px-8 pt-6 pb-8 mb-4  bg-white rounded">
                        <div className="flex my-5 w-full ">
                          <div className="w-full">
                            <label
                              htmlFor="upload"
                              className="flex items-center justify-center cursor-pointer gap-2 w-full max-w-[200px] px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
                            >
                              Upload Images/Video
                              <input
                                className="hidden"
                                id="upload"
                                name="image"
                                type="file"
                                multiple
                                accept="image/*,video/*"
                                onChange={(event) => {
                                  const file = Array.from(
                                    event.currentTarget.files
                                  );

                                  const fileUrls = file.map((fileItem) =>
                                    // URL.createObjectURL(fileItem)

                                    ({
                                      url: URL.createObjectURL(fileItem),
                                      type: fileItem.type,
                                    })
                                  );

                                  setFieldValue("productPhoto", file);
                                  console.log("NEW", values.productPhoto);

                                  setFilesPreview((prev) =>
                                    prev.length > 0
                                      ? [...prev, ...fileUrls]
                                      : fileUrls
                                  );

                                  setImgCheck(true);
                                }}
                              />
                            </label>

                            <div className="my-6 grid grid-cols-4  gap-5 w-full ">
                              {[...Array(4)].map((_, i) => {
                                return filesPreview &&
                                  filesPreview.length > 0 &&
                                  filesPreview[i] ? (
                                  filesPreview[i].type.startsWith("image") ? (
                                    <figure
                                      className="m-0 p-0 border-2 border-dashed border-gray-600 max-w-[100px] h-[80px] w-full rounded-sm  relative"
                                      key={i}
                                    >
                                      <img
                                        className="w-full h-full object-cover"
                                        src={filesPreview[i].url}
                                        alt="product img"
                                      />

                                      <button
                                        className="absolute -top-4 -right-[10px] text-red-500 text-sm"
                                        onClick={() =>
                                          handleDeleteFile(filesPreview[i].url)
                                        }
                                        type="button"
                                      >
                                        <FaTimes />
                                      </button>
                                    </figure>
                                  ) : (
                                    <div
                                      className="m-0 p-0 border-2 border-dashed border-gray-600 max-w-[100px] h-[80px] w-full relative"
                                      key={i}
                                    >
                                      <video
                                        className="w-full h-full object-cover"
                                        controls
                                        alt="product media"
                                      >
                                        <source
                                          src={filesPreview[i].url}
                                          type={filesPreview[i].type}
                                        ></source>
                                      </video>
                                      <button
                                        className="absolute -top-4 -right-[10px] text-red-500 text-sm"
                                        onClick={() =>
                                          handleDeleteFile(filesPreview[i].url)
                                        }
                                        type="button"
                                      >
                                        <FaTimes />
                                      </button>
                                    </div>
                                  )
                                ) : (
                                  <figure
                                    className="m-0 p-0 border-2 border-dashed border-gray-600 max-w-[100px] h-[80px] w-full rounded-lg overflow-hidden"
                                    key={i}
                                  >
                                    <img
                                      className="w-full h-full object-cover"
                                      src={ImageHolder}
                                      alt="product img"
                                    />
                                  </figure>
                                );
                                // <figure
                                //   className="m-0 p-0 border-2 border-dashed border-gray-600 max-w-[100px] h-[80px] w-full rounded-lg overflow-hidden"
                                //   key={i}
                                // >
                                //   <img
                                //     className="w-full h-full object-cover"
                                //     src={
                                //       filesPreview &&
                                //       filesPreview.length > 0 &&
                                //       filesPreview[i]
                                //         ? filesPreview[i]
                                //         : ImageHolder
                                //     }
                                //     alt="product img"
                                //   />
                                // </figure>
                              })}
                            </div>

                            {/* <label
                              className="block mb-2 text-sm font-bold text-gray-700 md:mt-2 text-center "
                              name="productPhoto"
                            >
                              Product Image
                            </label> */}
                            <ErrorMessage
                              className="text-red-600 text-xs text-center"
                              name="productPhoto"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="flex justify-around ">
                          <div className="flex flex-col">
                            <div className="flex flex-col justify-around  my-3">
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="name"
                                  >
                                    Name
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    type="text"
                                    name="name"
                                    placeholder="Enter product name"
                                  />
                                  <ErrorMessage
                                    className="text-red-600 text-xs"
                                    name="name"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm mt-2 font-bold text-gray-700 md:mt-2"
                                    htmlFor="type"
                                  >
                                    Brand
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="select"
                                    name="brand"
                                    onChange={(event) => {
                                      handleBrand(event, setFieldValue);
                                      handleChange(event);
                                    }}
                                  >
                                    <option value="" hidden selected>
                                      Select Brand Here
                                    </option>
                                    {categoryItems.map((category, index) => (
                                      <option
                                        key={index}
                                        value={category.brand}
                                      >
                                        {category.brand}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-600 text-xs font-thin"
                                    name="brand"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* {handleUserChange(values.brand)} */}
                            <div className="flex justify-around flex-col ">
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="category"
                                  >
                                    Category
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="select"
                                    name="category"
                                    onChange={(event) => handleChange(event)}
                                  >
                                    <option value="" hidden selected>
                                      Select Category Here
                                    </option>
                                    {category.map((category, index) => (
                                      <option key={index} value={category}>
                                        {category}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-600 text-xs"
                                    name="category"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="subCategory"
                                  >
                                    Sub-Category
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="select"
                                    name="subCategory"
                                  >
                                    <option value="" hidden selected>
                                      Select Sub-Category Here
                                    </option>
                                    {subCategory.map((category, index) => (
                                      <option key={index} value={category}>
                                        {category}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-600 text-xs font-thin"
                                    name="subCategory"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col justify-around my-2">
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2  text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="type"
                                  >
                                    Type
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="select"
                                    name="type"
                                  >
                                    <option value="" hidden selected>
                                      Select Type Here
                                    </option>
                                    {brandType.map((category, index) => (
                                      <option key={index} value={category}>
                                        {category}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    className="text-red-600 text-xs"
                                    name="type"
                                    component="div"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* product variations */}
                            <div className="flex flex-col justify-around my-2">
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2  text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="variations"
                                  >
                                    Add Product Variations
                                  </label>

                                  <Field
                                    className="w-full px-3 py-2 mb-3 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    type="text"
                                    name="variationName"
                                    placeholder="Enter variation name"
                                    onChange={(e) =>
                                      handleChangeVariationInputs(e)
                                    }
                                  />

                                  <Field
                                    className="w-full px-3 py-2 mb-3 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    type="number"
                                    name="variationPrice"
                                    placeholder="Enter variation price"
                                    onChange={(e) =>
                                      handleChangeVariationInputs(e)
                                    }
                                  />

                                  <Field
                                    className="w-full px-3 py-2 mb-3 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    type="number"
                                    name="variationStock"
                                    placeholder="Enter variation units"
                                    onChange={(e) =>
                                      handleChangeVariationInputs(e)
                                    }
                                  />

                                  <div className="flex w-full justify-end">
                                    <button
                                      className="flex items-center justify-center cursor-pointer gap-2 w-fit mb-4 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
                                      type="button"
                                      onClick={handleAddVariations}
                                    >
                                      Add
                                    </button>
                                  </div>

                                  <ul className="regions border border-gray-300 py-2 mr-8 max-w-[400px] w-full h-[100px] overflow-y-auto px-2 rounded-md flex flex-wrap gap-2 items-start">
                                    {productsVariations.length > 0 &&
                                      productsVariations.map((variation, i) => (
                                        <li
                                          className="flex gap-2 items-center justify-between px-3 py-1 bg-gray-200 rounded-3xl"
                                          key={i}
                                        >
                                          <span className="text-xs capitalize">
                                            {variation.stock === 1
                                              ? `${variation.stock} unit`
                                              : `${variation.stock} units`}{" "}
                                            of {variation.name} :{" "}
                                            {variation.price}
                                          </span>
                                          <button
                                            type="button"
                                            className="text-xs"
                                            onClick={() =>
                                              handleDeleteVariation(
                                                variation.name
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon="times" />
                                          </button>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            {/* variations end */}
                          </div>
                          <div className="flex flex-col">
                            <div className="flex flex-col justify-around my-3">
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 text-sm font-bold text-gray-700 "
                                    htmlFor="description"
                                  >
                                    Description
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2 text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    as="textarea"
                                    type="text"
                                    rows="5"
                                    name="description"
                                  />
                                  <ErrorMessage
                                    className="text-red-600 text-xs"
                                    name="description"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className="md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 mt-2 text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="units"
                                  >
                                    Stock Units
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    type="number"
                                    name="units"
                                    id="units"
                                  />
                                  <ErrorMessage
                                    className="text-red-600 text-xs font-thin"
                                    name="units"
                                    component="div"
                                  />
                                </div>
                              </div>
                              <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full">
                                  <label
                                    className="block mb-2 mt-2 text-sm font-bold text-gray-700 md:mt-2"
                                    htmlFor="price"
                                  >
                                    Default Price
                                  </label>
                                  <Field
                                    className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                    type="number"
                                    name="price"
                                    id="price"
                                  />
                                  <ErrorMessage
                                    className="text-red-600 text-xs font-thin"
                                    name="price"
                                    component="div"
                                  />
                                </div>
                              </div>
                              {/* roles prices */}
                              {!loadingRoles && rolesData.length > 0 && (
                                <>
                                  {rolesData.map((roles) => {
                                    return (
                                      <div className=" md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                        <div className=" md:mr-2 md:mb-0 md:w-full">
                                          <label
                                            className="block mb-2 mt-2 text-sm font-bold text-gray-700 md:mt-2 capitalize"
                                            htmlFor={`${roles.role}-price`}
                                          >
                                            {roles.role} Price
                                          </label>
                                          <Field
                                            className="w-full px-3 py-2  text-xs leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                            type="number"
                                            name={`${roles.role}-price`}
                                            id={`${roles.role}-price`}
                                          />
                                          <ErrorMessage
                                            className="text-red-600 text-xs font-thin"
                                            name={`${roles.role}-price`}
                                            component="div"
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                              {/* ///// */}
                              <div className="md:flex md:justify-between w-60 xl:w-44 lg:w-36 md:w-full md:mb-0">
                                <div className=" md:mr-2 md:mb-0 md:w-full"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 flex items-center justify-center gap-2 sm:flex-col text-center">
                          <button
                            className="flex items-center justify-center cursor-pointer gap-2 w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {!status ? "Update" : "Submit"}{" "}
                            {isLoading && <Loader />}
                          </button>
                          <button
                            className={`w-36 px-4 py-2 font-semibold text-gray-600 bg-[#E9C95D] rounded hover:bg-[#E9D95D] focus:outline-none focus:shadow-outline ${
                              !status ? "hidden" : ""
                            }`}
                            type="reset"
                          >
                            Reset
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

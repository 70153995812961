import React, { useState } from "react";
import CountUp from "react-countup";
import DatePicker from "react-datepicker";
import { Loader } from "rsuite";

import "react-datepicker/dist/react-datepicker.css";

const AnalyticsCard = ({
  bg = "bg-white",
  icon,
  title,
  data,
  showFilter = false,
  optionsData,
  handleChange,
  value,
  showDatePicker = false,
  startDate,
  endDate,
  isIncome = false,
  isLoading,
}) => {
  return (
    <div
      className={`${bg} flex flex-col my-2 justify-between rounded-lg py-5 px-4 h-[200px] w-full shadow-xl border w-xl text-[#575757]`}
    >
      {/* w-64 */}
      <article className="flex items-center justify-between text-base">
        <p className="font-medium capitalize ">{title}</p>

        <span className="text-3xl">{icon}</span>
      </article>
      {isLoading ? (
        <Loader />
      ) : (
        <CountUp
          // start={data - 50 > 0 ? data - 50 : 0 ?? 0}
          start={0}
          end={data ?? 0}
          delay={0}
          duration={1}
        >
          {({ countUpRef }) => (
            <h1 className="font-semibold text-[32px]">
              {isIncome ? (
                <>
                  €<span ref={countUpRef} />
                </>
              ) : (
                <span ref={countUpRef} />
              )}
            </h1>
          )}
        </CountUp>
      )}

      {showFilter && (
        <div
          className={`border border-gray-400 h-7 rounded-[4px] overflow-hidden`}
        >
          <select
            className="w-full outline-none h-full border-none bg-transparent"
            onChange={handleChange}
            value={value}
          >
            <option value="" className="">
              Select filter option
            </option>
            {optionsData}
          </select>
        </div>
      )}

      {showDatePicker && (
        <div
          className={`border border-gray-400 h-7 rounded-[4px] overflow-hidden`}
        >
          <DatePicker
            className="w-full h-full px-2 bg-transparent"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            // onChange={(update) => {
            //   setDateRange(update);
            // }}
            onChange={handleChange}
            isClearable={true}
            placeholderText="Enter date range"
          />
        </div>
      )}
    </div>
  );
};

export default AnalyticsCard;
